import _ from 'lodash'
import { FeeConfig, IGroupFee } from 'models'
import React, { useEffect, useState } from 'react'
import { formatDate } from './commons-function'
import { FeeConfigStatus } from 'helpers/constants'
import stringHelper from 'helpers/string-helper'
import { numberHelper } from 'helpers/number-helper'
interface TypeProps {
  data: Array<FeeConfig> | undefined
  onClickDetail: Function
}

function ListFeeConfig(props: TypeProps) {
  const { data, onClickDetail } = props
  const [groupFee, setGroupFee] = useState<IGroupFee[]>([])
  const handleClickDetail = (item: FeeConfig) => {
    onClickDetail(item)
  }
  useEffect(() => {
    const groups = _.chain(data)
      .groupBy('type')
      .map((value, key) => ({ group: key, items: value }))
      .value() as IGroupFee[]

    setGroupFee(groups)
  }, [data])
  return (
    <>
      {data && (
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr className="text-muted" style={{ verticalAlign: 'top' }}>
                  <th className="text-center">Fee Type</th>
                  <th className="text-center">Payment Method</th>
                  <th className="text-center">Fee</th>
                  <th className="text-center">From Date</th>
                  <th className="text-center">To Date</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Edit</th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.sort((a, b) => (a.type > b.type ? 1 : -1))
                  .map((idx: FeeConfig) => (
                    <tr key={idx?.id}>
                      <td>{idx.type}</td>
                      <td className="text-center">{stringHelper.upperString(idx.paymentMethod)}</td>
                      <td className="text-center">
                        {idx.feeRate > 0 ? numberHelper.parseToPercent(idx.feeRate) : 0}%
                      </td>
                      <td className="text-center">
                        {idx.appliedFromDate && formatDate(idx.appliedFromDate)}
                      </td>
                      <td className="text-center">
                        {idx.appliedToDate && formatDate(idx.appliedToDate)}
                      </td>
                      <td className="text-center">
                        {idx.status === FeeConfigStatus.Active ? (
                          <span className="badge badge-sm bg-green">{FeeConfigStatus.Active}</span>
                        ) : (
                          <span className="badge badge-sm bg-secondary">
                            {FeeConfigStatus.Inactive}
                          </span>
                        )}
                      </td>
                      <td className="d-flex align-items-center justify-content-center">
                        <div className="row gx-1">
                          <div className="col-auto">
                            <button
                              className=" btn btn-primary btn-sm py-0"
                              onClick={() => {
                                handleClickDetail(idx)
                              }}
                            >
                              <i className="bi bi-pencil"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default ListFeeConfig
