import React, { useState } from 'react'
import OpenAlert from './sweetAlert'
import {
  FEE_TYPE,
  FORMAT_DATE_DATEPICKER,
  FeeConfigStatus,
  PAYMENT_METHOD,
} from 'helpers/constants'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FeeConfig } from 'models'
import { Form } from 'react-bootstrap'
import { numberHelper } from 'helpers/number-helper'

const STATUS = [
  { title: FeeConfigStatus.Active, value: FeeConfigStatus.Active },
  { title: FeeConfigStatus.Inactive, value: FeeConfigStatus.Inactive },
]
const ACTIONS = { ADD: 'ADD', EDIT: 'EDIT' }
function FormConfigFee(props: any) {
  const { onSubmit, action, itemDetail, isLoading } = props
  const itemDetailEdit: FeeConfig | undefined = itemDetail
  const initStartDate = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString()
  const initValues = {
    type: itemDetailEdit?.type || '',
    paymentMethod: itemDetailEdit?.paymentMethod || '',
    isVRC20: itemDetailEdit?.isVRC20 || true,
    feeRate: numberHelper.parseToPercent(itemDetailEdit?.feeRate)?.toString() || '',
    appliedForId: itemDetailEdit?.appliedForId || null,
    appliedFromDate: itemDetailEdit?.appliedFromDate || initStartDate,
    appliedToDate: itemDetailEdit?.appliedToDate || null,
    status: itemDetailEdit?.status || FeeConfigStatus.Active,
  }
  const [formValues, setFormValues] = useState(initValues)

  const messageError = () => {
    if (!formValues.type) return 'Type of fee invalid'
    if (!formValues.paymentMethod) return 'Payment method invalid'
    if (Number(formValues.feeRate) <= 0) return 'Fee rate must be greater than zero'
    if (!formValues.appliedFromDate) return 'Applied from date must be choose'
    return
  }
  const handleSubmit = (e: any) => {
    if (
      formValues.type &&
      formValues.paymentMethod &&
      Number(formValues.feeRate) > 0 &&
      formValues.appliedFromDate
    ) {
      onSubmit(formValues)
    } else {
      OpenAlert(`${messageError()}`, ``, 'error')
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="form-group">
                  <label className="mb-1 fw-bold">Type Of Fee</label>
                  <select
                    className="form-select border w-100"
                    value={formValues.type}
                    onChange={(event) => {
                      setFormValues({ ...formValues, type: event.target.value })
                    }}
                    data-placeholder="Select Type"
                    disabled={action === ACTIONS.EDIT}
                  >
                    {FEE_TYPE?.map((item: any) => (
                      <option value={item.value} key={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="form-group">
                  <label className="mb-1 fw-bold">Payment Method</label>
                  <select
                    className="form-select border w-100"
                    value={formValues.paymentMethod}
                    onChange={(event) => {
                      setFormValues({ ...formValues, paymentMethod: event.target.value })
                    }}
                    data-placeholder="Select Payment Method"
                    disabled={action === ACTIONS.EDIT}
                  >
                    {PAYMENT_METHOD?.map((item: any) => (
                      <option value={item.value} key={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="form-group border-0 shadow-none">
                  <label className="mb-1 fw-bold">Fee (%)</label>
                  <div className="rt-input-input">
                    <input
                      maxLength={4}
                      type="text"
                      placeholder="Custom Fee"
                      value={formValues.feeRate}
                      required
                      className="form-control border"
                      onChange={(event) => {
                        setFormValues({ ...formValues, feeRate: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3" style={{ flexDirection: 'column' }}>
                <label className="mb-1 fw-bold">Apply From Date</label>
                <br />
                <DatePicker
                  selected={
                    formValues.appliedFromDate ? new Date(formValues.appliedFromDate) : null
                  }
                  onChange={(date: Date) =>
                    setFormValues({ ...formValues, appliedFromDate: date.toISOString() })
                  }
                  selectsStart
                  dateFormat={FORMAT_DATE_DATEPICKER}
                  className="form-control border"
                  placeholderText="Select from date"
                  disabled={action === ACTIONS.EDIT}
                />
              </div>

              <div className="col-12 mb-3">
                <div className="form-group border-0 shadow-none">
                  <label className="mb-1 fw-bold">Apply To Date</label>
                  <br />
                  <DatePicker
                    selected={formValues.appliedToDate ? new Date(formValues.appliedToDate) : null}
                    onChange={(date: Date) => {
                      setFormValues({ ...formValues, appliedToDate: date.toISOString() })
                    }}
                    selectsStart
                    dateFormat={FORMAT_DATE_DATEPICKER}
                    className="form-control border"
                    placeholderText="Select to date"
                    disabled={action === ACTIONS.EDIT}
                  />
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="form-group">
                  <label className="mb-1 fw-bold">Status</label>
                  <select
                    className="form-select border w-100"
                    value={formValues.status}
                    onChange={(event) => {
                      setFormValues({ ...formValues, status: event.target.value })
                    }}
                    data-placeholder="Select Payment Method"
                    // disabled={action === ACTIONS.EDIT}
                  >
                    {STATUS?.map((item: any) => (
                      <option value={item.value} key={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3 pb-3">
              <div className="col col-md-6">
                <button
                  className="btn  btn-theme w-100"
                  type="button"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  <span className="d-inline">Submit</span>
                  {isLoading && <span className="spinner-border spinner-border-sm" role="status" />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default FormConfigFee
