import React, { useEffect, useState } from 'react'
import ListManagePackage from './package-list'
import Loader from 'components/loader'
import { ModePackageFitter, PackageDtoModel, PackagesSettingDto } from 'models'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import EditPackagePayment from './edit-package-payment'
import FilterPackage from './filter-package'

function PackagesSettingComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dataTable, setDataTable] = useState<Array<PackageDtoModel>>([])
  const [filter, setFilter] = useState<ModePackageFitter>({
    group: '',
    status: '',
    packageName: '',
  })
  const loadData = async () => {
    try {
      const response = await axiosClient().get(END_POINTS.PACKAGE_SETTING.GET_PACKAGES(), {
        params: {
          keySearch: filter.packageName,
          packageSettingGroup: filter.group,
          blockPurchase: filter.status === '' ? null : filter.status === 'true',
        },
      })

      const _data = response.data || []
      const _dataMap = _data.flatMap(
        (item: PackagesSettingDto) =>
          item?.packagesSetting?.map((pack, idxPack) => ({
            ...pack,
            packageSettingGroup: item.packageSettingGroup,
            rowSpan: idxPack === 0 ? item?.packagesSetting?.length : undefined,
          })) || [],
      )

      setDataTable(_dataMap)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [filter])

  return (
    <>
      <FilterPackage
        filter={filter}
        onFilter={(filter: ModePackageFitter) => {
          setFilter(filter)
        }}
      />
      <div className="card border-0 mt-2 rounded">
        <div className="card-header">
          <div className="row">
            <div className="col">
              <h4 className="mb-0">List Packages</h4>
            </div>
            {isLoading && <Loader />}
            <ListManagePackage data={dataTable} />
          </div>
        </div>
      </div>
      <EditPackagePayment />
    </>
  )
}
export default PackagesSettingComponent
