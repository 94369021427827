import { StatusPackage, TypePaymentMethod } from 'helpers/constants'
import { IPaymentMethodItem } from 'models'
import React from 'react'
import { Form } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
interface TypeProps {
  data: IPaymentMethodItem[]
  onClick: Function
  onChange: Function
}

function ListPaymentMethod(props: TypeProps) {
  const { data, onClick, onChange } = props
  function getPaymentMethodValue(type: string): string {
    return (TypePaymentMethod as any)[type]
  }
  function getStatusValue(status: string): boolean {
    return (StatusPackage as any)[status]
  }
  const transformData = (data: any) => ({
    id: data.id,
    name: data.name,
    type: data.type,
    status: getStatusValue(data.status),
    paymentMethodOptionDetails: data.paymentMethodOptionDetails.map((wallet: any) => ({
      tokenItemId: wallet.paymentMethodOptionItem.id,
      percentage: wallet.percentage,
      isFixedPercentage: wallet.isFixedPercentage,
    })),
  })

  return (
    <>
      {data && data.length > 0 && (
        <div className="table-responsive mb-4">
          <table className="table table-striped table-hover">
            <thead>
              <tr className="text-muted">
                <th>Name</th>
                <th>Payment</th>
                <th>Type</th>
                <th>Status</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{getPaymentMethodValue(item.type)} </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="switch"
                        id={`custom-switch-${uuidv4()}`}
                        className="me-2"
                        checked={getStatusValue(item.status)}
                        onChange={(e) => onChange(item)}
                      />
                      <span>{item.status}</span>
                    </div>
                  </td>
                  <td>
                    {' '}
                    <button
                      type="button"
                      onClick={() => {
                        onClick(transformData(item))
                      }}
                      className="btn btn-primary btn-sm py-0"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Update FeeTransfer"
                    >
                      <i className="bi bi-pencil"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ListPaymentMethod
