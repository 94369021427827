import { OidcConfig } from 'helpers/oidc-setting'
import { UserManager } from 'oidc-client-ts'

export interface AccessTokenClaims {
  iss: string
  aud: Array<string>
  client_id: string
  email: string
  permission: Array<string>
  scope: Array<string>
}

let client: UserManager = null as any
class OidcUserManager {
  get Oidc(): UserManager {
    if (!client) {
      client = new UserManager(OidcConfig)
    }
    return client
  }
}

export default new OidcUserManager()
