import Loader from 'components/loader'
import OpenAlert from 'components/sweetAlert'
import { GUID_EMPTY, MENU_PACKAGE_POPUP, PackageSettingGroup } from 'helpers/constants'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import { random } from 'lodash'
import {
  IMapDataPayment,
  IParamSetPayment,
  IPaymentMethodItem,
  ISearchPaymentMethod,
  MenuItem,
} from 'models'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store'
import { changePopup, changeTabPopup } from 'store/slice/packagesSlice'
import { v4 as uuidv4 } from 'uuid'
function EditPackagePayment() {
  const dispatch = useDispatch()
  const popup = useSelector((store: RootState) => store.package.popup)
  const packageEdit = useSelector((store: RootState) => store.package.packages)
  const activeTab = useSelector((store: RootState) => store.package.activeTabPopUp)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [paymentMethodForPackage, setPaymentMethodForPackage] = useState<any[]>([])
  const [paymentMethods, setPaymentMethods] = useState<IMapDataPayment[]>([])
  const [errorIds, setErrorIds] = useState<string[]>([])
  const [isCheckSubmid, setIsCheckSubmid] = useState<boolean>(false)
  const handleClick = (item: MenuItem, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    dispatch(changeTabPopup(item))
  }
  const handleSubmit = async () => {
    if (isIdMethodNull(paymentMethodForPackage)) {
      OpenAlert('Please select a Payment Method for each item.', '', 'warning')
      setIsCheckSubmid(true)
      return false
    }
    try {
      let bodyParams: IParamSetPayment = {
        entityType: packageEdit.packageSettingGroup,
        entityId: packageEdit.id,
        paymentMethodIds: extractMethodIds(paymentMethodForPackage),
      }
      setIsLoading(true)
      const query = await axiosClient().post(
        END_POINTS.PACKAGE_SETTING.SET_PAYMENT_METHOD_TO_PAYMENT_ITEM(),
        bodyParams,
      )
      if (query) {
        OpenAlert(`Update payment method for ${packageEdit.name} success!`, '', 'success')
        handleCancel()
      }
    } finally {
      setIsLoading(false)
      setIsCheckSubmid(false)
    }
  }

  function extractMethodIds(data: any) {
    return data.map((item: any) => item.methodId)
  }

  const handleCancel = () => {
    dispatch(changePopup(false))
  }
  function filterPaymentMethodsByType(paymentMethodList: any[]): any[] {
    return paymentMethodList.filter((paymentItem) => paymentItem.type === activeTab.value)
  }

  const loadPaymentMethod = async () => {
    try {
      const query: any = await axiosClient().post(
        END_POINTS.PACKAGE_SETTING.SEARCH_PAYMENT_METHOD(),
        {},
      )
      setPaymentMethods(query.data)
    } finally {
      setIsLoading(false)
    }
  }
  const loadPaymentMethodForPackage = async () => {
    try {
      setIsLoading(true)
      const bodyParams: ISearchPaymentMethod = {
        entity: {
          entityId: packageEdit.id,
          entityType: packageEdit.packageSettingGroup,
          isIncludeUnsetItems: false,
        },
      }
      const queryPayment = await axiosClient().post(
        END_POINTS.PACKAGE_SETTING.SEARCH_PAYMENT_METHOD(),
        bodyParams,
      )
      const PaymentList: IMapDataPayment[] = queryPayment.data.map(
        (paymentItem: IPaymentMethodItem) => ({
          ...paymentItem,
          randomId: uuidv4(),
          methodId: paymentItem.id,
        }),
      )
      setPaymentMethodForPackage(PaymentList)
    } finally {
      setIsLoading(false)
    }
  }
  const handleRemoveMethod = (id: string) => {
    const newData = paymentMethodForPackage.filter((item) => item.randomId !== id)
    setPaymentMethodForPackage(newData)
  }
  const handleAddMethod = () => {
    const initNewData = {
      randomId: uuidv4(),
      methodId: '',
      type: activeTab.value,
    }
    setPaymentMethodForPackage([...paymentMethodForPackage, initNewData])
  }
  useEffect(() => {
    if (GUID_EMPTY !== packageEdit.id && paymentMethods.length === 0) loadPaymentMethod()
  }, [packageEdit])

  useEffect(() => {
    if (popup.status !== false) loadPaymentMethodForPackage()
  }, [popup.status])

  const handleChangeMethod = (id: string, e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setPaymentMethodForPackage((prev) => updateList(prev, id, value))
    if (isCheckSubmid && value !== undefined) {
      setErrorIds((prevIds) => prevIds.filter((itemId) => itemId !== id))
    }
  }

  const updateList = (arr: IMapDataPayment[], id: string, methodId: string) => {
    return arr.map((item) => {
      if (item.randomId === id) {
        return { ...item, methodId }
      }
      return item
    })
  }

  function getPaymentTitle(label: string) {
    return label === 'Combine' || label === 'Single'
      ? `${label} payment method with tokens on the system`
      : label === 'Vlinkpay'
      ? `Payment via VLINKAPP`
      : `Pay with USDT (TRC20)`
  }
  function getPaymentName(label: string) {
    return label === 'Combine' || label === 'Single' ? label.toLowerCase() : label
  }

  function isIdExists(array: any[], idToCheck: string) {
    return array.some((item: any) => item.methodId === idToCheck)
  }

  function isIdMethodNull(paymentMethodForPackageList: any[]) {
    const emptyRandomIds: string[] = paymentMethodForPackageList
      .filter((wallet: any) => wallet.methodId.trim() === '')
      .map((wallet: any) => wallet.randomId)
    setErrorIds(emptyRandomIds)
    return emptyRandomIds.length > 0
  }

  return (
    <>
      <Modal
        show={popup.status}
        centered
        enforceFocus={false}
        onHide={() => dispatch(changePopup(false))}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Payment Methods For Package </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="nav nav-tabs justify-content-center nav-WinDOORS nav-lg mb-4 mt-4">
            {MENU_PACKAGE_POPUP.map((item: MenuItem) => (
              <li className="nav-item" key={uuidv4()}>
                <Link
                  className={`nav-link ${activeTab.value === item.value ? 'active' : ''}`}
                  to="#"
                  onClick={(e) => handleClick(item, e)}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
          <div className="position-relative">
            {isLoading && <Loader />}
            <>
              <h6 className="text-center mb-3">{getPaymentTitle(activeTab.label)}</h6>
              <div>
                {filterPaymentMethodsByType(paymentMethodForPackage).map((item, index) => (
                  <div
                    className={`payment-wallet-item payout-wallet-item mb-4 ${
                      errorIds.includes(item.randomId) ? 'border border-danger' : ''
                    }`}
                    key={`${item.randomId}`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary iconbutton mx-2"
                      onClick={() => handleRemoveMethod(item.randomId)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                    <div className="row gx-1">
                      <div className="col-12">
                        <label>
                          Method <span className="text-danger">*</span>
                        </label>
                        <div className="mb-2 mt-1 d-flex">
                          <select
                            className={`form-select border w-100 `}
                            value={item?.methodId}
                            name="methodId"
                            id={`${item.randomId}`}
                            onChange={(e: any) => handleChangeMethod(item.randomId, e)}
                          >
                            <option disabled value={''}>
                              Select a {getPaymentName(activeTab.label)} method
                            </option>
                            {filterPaymentMethodsByType(paymentMethods).map(
                              (paymentItem: IPaymentMethodItem) => (
                                <option
                                  value={paymentItem.id}
                                  key={paymentItem.id}
                                  disabled={isIdExists(
                                    filterPaymentMethodsByType(paymentMethodForPackage),
                                    paymentItem.id,
                                  )}
                                >
                                  {paymentItem?.name}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
            <button type="button" className="btn btn-md btn-link" onClick={handleAddMethod}>
              <i className="bi bi-plus"></i> Add {activeTab.label} Method
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          <div className="row w-100 align-items-center justify-content-center">
            <div className="col-auto">
              <Button
                variant="secondary text-white"
                className="border-0"
                data-toggle="tooltip"
                data-placement="top"
                title={'Cancel'}
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="col-auto">
              <Button
                type="submit"
                variant="primary text-white"
                className="border-0"
                data-toggle="tooltip"
                data-placement="top"
                title={'Update'}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditPackagePayment
