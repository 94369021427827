import React from 'react'
function Loader() {
  return (
    <>
      <div className="loading-data">
        <div className="spinner-border text-primary">
          <span className="sr-only"></span>
        </div>
      </div>
    </>
  )
}

export default Loader
