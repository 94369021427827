export const TOKEN_ADMIN = 'TOKEN_ADMIN'
export const LOGIN_ROUTE = '/login'
export const TOKEN = 'TOKEN'
export const ROLE = {
  SYS_ADMIN_ROLE: 'System Admin',
  ADMINISTRATOR: 'Administrator',
  APPROVER: 'Approver',
  MEMBER_ROLE: 'Member',
}
export const KEY_EDIT_USER_PROFILE = {
  USERNAME: 'USERNAME',
  STATUS: 'STATUS',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  PIN_STATUS: 'PIN_STATUS',
}
export const USER_ATTRIBUTE = {
  ACTIVE: 'Active',
  DEACTIVE: 'Deactive',
  LOCK: 'Lock',
  UNLOCK: 'Unlock',
}

export const FORMAT_DATE = 'MMM Do, YYYY'
export const FORMAT_DATE_DATEPICKER = 'MMM do, yyyy'
export const FORMAT_DATE_PARAMS = 'YYYY-MM-DD'

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export const BONUSDAILY_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  PAID: 'Paid',
  LEADERSHIPPAID: 'LeadershipPaid',
}

export const ERROR_401_EVENT = 'ERROR_401_EVENT'
export const ERROR_403_EVENT = 'ERROR_403_EVENT'
export const DEFAULT_PAGE_SIZE = 10
export const LOADING = 'LOADING'

export const enum WithdrawStatusValue {
  Reject = 'Reject',
  Completed = 'Completed',
  Pending = 'Pending',
  Approved = 'Approved',
  Scheduled = 'Scheduled',
  Error = 'Error',
}

export const WithdrawStatus = [
  { text: WithdrawStatusValue.Completed, value: WithdrawStatusValue.Completed },
  { text: WithdrawStatusValue.Error, value: WithdrawStatusValue.Reject },
]
export const SEARCH_BY_FIELD = {
  USER_USERNAME: 'username',
  USER_EMAIL: 'email',
  USER_PHONE: 'phoneNumber',
  SPONSER_USERNAME: 'SPONSER_USERNAME',
  TRANSACTION_TYPE: 'Type',
  ALL: 'All',
  CATEGORY: 'CATEGORY',
}
export const TYPE_SEARCH = {
  CONTAIN: 'Contains',
  EQUAL: 'Equals',
  STARTWITH: 'StartsWith',
}

export const STYPE_SELECT_REACT = {
  borderColor: '#dee2e6',
  boxShadow: 'none',
  borderRadius: '0.375rem',
  minHeight: '34px',
  height: '34px',
  color: 'black',
}
export const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles: any, { isFocused, isSelected }: { isFocused: any; isSelected: any }) => {
    return {
      ...styles,
      ...STYPE_SELECT_REACT,
      backgroundColor: isFocused ? '#AADAFA' : 'white',
    }
  },
}
export const colourStylesLg = {
  control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles: any, { isFocused, isSelected }: { isFocused: any; isSelected: any }) => {
    return {
      ...styles,
      ...STYPE_SELECT_REACT,
      backgroundColor: isFocused ? '#AADAFA' : 'white',
      height: 'auto',
    }
  },
}
export const RECEIPT_STATUS = {
  INPROGRESS: 'InProgress',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  DELETED: 'Deleted',
  NEW: 'New',
  ASKRESUBMIT: 'AskedToResubmit',
  INPROGRESS2: 'InProgress2',
  PAID: 'Paid',
  PARTIALPAID: 'PartialPaid',
  UNPAID: 'Unpaid',
  HISTORY: 'History',
}
export const ARR_RECEIPT_STATUS = [
  {
    text: 'InProgress',
    value: RECEIPT_STATUS.INPROGRESS,
  },
  {
    text: 'Approved',
    value: RECEIPT_STATUS.APPROVED,
  },
  {
    text: 'Rejected',
    value: RECEIPT_STATUS.REJECTED,
  },
  {
    text: 'Deleted',
    value: RECEIPT_STATUS.DELETED,
  },
  {
    text: 'New',
    value: RECEIPT_STATUS.NEW,
  },
  {
    text: 'AskedToResubmit',
    value: RECEIPT_STATUS.ASKRESUBMIT,
  },
  {
    text: 'InProgress2',
    value: RECEIPT_STATUS.INPROGRESS2,
  },
  {
    text: 'Paid',
    value: RECEIPT_STATUS.PAID,
  },
  {
    text: 'PartialPaid',
    value: RECEIPT_STATUS.PARTIALPAID,
  },
  {
    text: 'Unpaid',
    value: RECEIPT_STATUS.UNPAID,
  },
]
export const RECEIPT_PERCENT = 20

export const TRANSACTION_SEARCH_CATEGORY = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Pay Out',
    value: 'PaidOut',
  },
  {
    text: 'Revenue',
    value: 'Revenue',
  },
]
export const TRANSACTION_TYPES = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Lucky tickets',
    value: 'Lottery',
  },
  {
    text: 'Network Daily Bonus',
    value: 'NetworkDailyBonus',
  },
  {
    text: 'Network Leadership Bonus',
    value: 'NetworkLeadershipBonus',
  },
  {
    text: 'Annual Pass',
    value: 'YearlyPass',
  },
  {
    text: 'Monthly Pass',
    value: 'MonthlyPass',
  },

  {
    text: 'Cashback Reward',
    value: 'CashbackReward',
  },
  {
    text: 'Cashback Daily PaidOut',
    value: 'CashbackDailyPaidOut',
  },
  {
    text: 'Global Bonus',
    value: 'NetworkGlobalBonus',
  },
]

export const TRANSACTION_CATEGORY = {
  PAIDOUT: 'PaidOut',
  REVENUE: 'Revenue',
}
export const TRANSACTION_CATEGORY_TEXT = {
  PAIDOUT: 'Pay Out',
}

export const VIEW_CHART = {
  REVENUE: 'REVENUE',
  PAYOUT: 'PAYOUT',
}
export const STATISTIC_TYPE = {
  NETWORK_DAILY_BONUS: 'Network Daily Bonus',
  LEADER_SHIP: 'Leadership Matching',
  GLOBAL_BONUS: 'Global Bonus',
  CASHBACK_DAILY: 'Cashback Daily Pay Out',
  CASBACK_REWARD: 'Cashback Reward Monthly',
  MONTHLY_PASS: 'Monthly Pass',
  YEARLY_PASS: 'Annual Pass',
  LUCKY_TICKETS: 'Lucky Tickets',
}
export const TRANSACTION_FILTER_TYPES = [
  {
    text: 'All',
    value: '',
    name: 'All',
  },
  {
    text: 'Transfer',
    value: 'Transfer',
    name: 'Transfer',
  },
  {
    text: 'Receive',
    value: 'Receive',
    name: 'Receive',
  },
]
export const WALLET_TYPES = [
  // {
  //   text: 'All',
  //   value: '',
  //   name: '',
  // },
  {
    text: 'USDT',
    value: 'Usdt',
    name: 'USDT_WALLET',
  },
  {
    text: 'VLG',
    value: 'Vlg',
    name: 'VLG_WALLET',
  },
  {
    text: 'VMM',
    value: 'Vmm',
    name: 'VMM_WALLET',
  },
  {
    text: 'VUSD',
    value: 'Vusd',
    name: 'VUSD_WALLET',
  },
  {
    text: 'VND',
    value: 'Vnd',
    name: 'VND_WALLET',
  },
  {
    text: 'USD',
    value: 'Usd',
    name: 'USD_WALLET',
  },

  {
    text: 'VPC',
    value: 'Vpc',
    name: 'VPC_WALLET',
  },
  {
    text: 'VlgStalking',
    value: 'VlgStalking',
    name: 'VLG_STALKING_WALLET',
  },
  {
    text: 'VPC BONUS',
    value: 'VpcBonus',
    name: 'VPCBONUS_WALLET',
  },
]
export const WALLET_TOPUP = [
  {
    text: 'VMM',
    value: 'Vmm',
    name: 'VMM_WALLET',
  },
  {
    text: 'VUSD',
    value: 'Vusd',
    name: 'VUSD_WALLET',
  },

  {
    text: 'VPC',
    value: 'Vpc',
    name: 'VPC_WALLET',
  },
]

export const enum MEMBERSHIP_PACKAGE_TYPES {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}
export const SEARCH_RECEIPT_BY_DATE = {
  CYCLES: 'CYCLES',
  USED_DATE: 'USED_DATE',
  END_DATE: 'END_DATE',
}
export const ORDER_RECEIPT_BY = {
  USED_DATE: 'UsedDate',
  END_DATE: 'EndDate',
  USERNAME: 'Username',
  DEFAULT: 'DEFAULT',
}
export enum ActivationMembership {
  FromToday = 'FromToday',
  FromLastExpirationDate = 'FromLastExpirationDate',
}
export enum PaidMembership {
  None = 'None',
  PaidAndDebt = 'PaidAndDebt',
}

export const ActivationMembershipType = [
  {
    label: 'From Today',
    value: ActivationMembership.FromToday,
  },
  {
    label: 'From Last Expiration Date',
    value: ActivationMembership.FromLastExpirationDate,
  },
]
export const PaidMembershipOption = [
  {
    label: 'None',
    value: PaidMembership.None,
  },
  {
    label: 'Paid And Debt',
    value: PaidMembership.PaidAndDebt,
  },
]
export const TYPE_BIGDATA_PLATINUM = 'BigdataPlatinum'
export const PACKAGE_TYPES = {
  DataReceipt: 'DataReceipt',
  BigdataPlatinum: 'BigdataPlatinum',
  AutoBinary: 'AutoBinary',
  LinksSystem: 'LinksSystem',
  BigdataDaily: 'BigdataDaily',
  BigdataTravel: 'BigdataTravel',
}

export const CurrencyUnits = [
  {
    text: 'Vmm',
    value: 'Vmm',
  },
]

export const SelectWinnerValue = {
  MEMBER: 'SetBySystem',
  RANDOM: 'Random',
  NOBODY: 'NoWinner',
}
export const SelectWinnerText: any = {
  SetBySystem: 'Selected',
  Random: 'Random',
  NoWinner: 'Nobody',
}
export const SelectWinner = [
  { text: 'Nobody', value: SelectWinnerValue.NOBODY },
  { text: 'Random lucky numner', value: SelectWinnerValue.RANDOM },
  { text: 'Set lucky number', value: SelectWinnerValue.MEMBER },
]

export const CyclesStatusValue = {
  ACTIVE: 'Active',
  DONE: 'Done',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
}

export const SelectCyclesStatus = [
  { text: 'Active', value: CyclesStatusValue.ACTIVE },
  { text: 'Pending', value: CyclesStatusValue.PENDING },
  { text: 'Done', value: CyclesStatusValue.DONE },
  { text: 'Cancelled', value: CyclesStatusValue.CANCELLED },
]

export const SelectCyclesTypeValue = {
  LATEST: '1',
  SETBYADMIN: '2',
}
export const SelectCyclesType = [
  { text: 'Current Lucky Tickets Event', value: SelectCyclesTypeValue.LATEST },
  { text: 'Choose Lucky Tickets Event', value: SelectCyclesTypeValue.SETBYADMIN },
]

export const LotteryTicketStatusValue = {
  Active: 'Active',
  Done: 'Done',
  Pending: 'Pending',
  Jackpot: 'Jackpot',
  Cancelled: 'Cancelled',
}
export const LotteryTicketStatusSelect = [
  { text: 'All', value: '' },
  { text: 'Active', value: LotteryTicketStatusValue.Active },
  { text: 'Pending', value: LotteryTicketStatusValue.Pending },
  { text: 'Jackpot', value: LotteryTicketStatusValue.Jackpot },
  { text: 'Done', value: LotteryTicketStatusValue.Done },
  { text: 'Cancelled', value: LotteryTicketStatusValue.Cancelled },
]

export const SelectMonthlyPassTypeValue = {
  AiBigdata: 'Bigdata',
  Package: 'Package',
  MonthlyPass: 'MonthlyPass',
}
export const SelectMonthlyPassType = [
  { text: 'Bigdata package', value: SelectMonthlyPassTypeValue.AiBigdata },
  // { text: 'Data receipt package', value: SelectMonthlyPassTypeValue.Package },
  { text: ' Monthly pass', value: SelectMonthlyPassTypeValue.MonthlyPass },
]
export const TicketPrizePlanStatusValue = {
  WaitingPayment: 'WaitingPayment',
  Paid: 'Paid',
  Pending: 'Pending',
  Expired: 'Expired',
}
export const TicketPrizePlanStatusText: any = {
  WaitingPayment: 'WaitingPayment',
  Paid: 'Paid',
  Pending: 'Processing',
  Expired: 'Expired',
}

export enum NftType {
  All = 'All',
  CashBackNft = 'CashBackNft',
  MoongleFarmNft = 'MoongleFarmNft',
}

export enum NftStatus {
  All = 'All',
  Issued = 'Issued',
  Active = 'Active',
  Inactive = 'Inactive',
  Burnt = 'Burnt',
}

export enum imageType {
  All = '',
  BackgroundLuckyBanner = 'BackgroundLuckyBanner',
  BackgroundLuckyPool = 'BackgroundLuckyPool',
  BackgroundSlider = 'BackgroundSlider',
  BackgroundSliderPackages = 'BackgroundSliderPackages',
  MallBackgroundSlider = 'MallBackgroundSlider',
  MallBackgroundSliderPackages = 'MallBackgroundSliderPackages',
}

export enum languageImage {
  All = '',
  En = 'En',
  Vi = 'Vi',
  Ja = 'Ja',
}

export const additionalOptions = [
  { value: languageImage.All, label: 'All' },
  { value: languageImage.En, label: 'English' },
  { value: languageImage.Vi, label: 'Việt Nam' },
  { value: languageImage.Ja, label: 'Japan' },
]

export const typeOptions = [
  { value: imageType.All, label: 'All' },
  { value: imageType.BackgroundLuckyBanner, label: 'Background Lucky Banner' },
  { value: imageType.BackgroundLuckyPool, label: 'Background Lucky Pool' },
  { value: imageType.BackgroundSlider, label: 'Background Slider' },
  { value: imageType.BackgroundSliderPackages, label: 'Background Slider Packages' },
  { value: imageType.MallBackgroundSlider, label: 'Mall Background Slider' },
  { value: imageType.MallBackgroundSliderPackages, label: 'Mall Background Slider Packages' },
]

export const additionalOptionsEdit = [
  { value: 'All', label: 'All' },
  { value: languageImage.En, label: 'English' },
  { value: languageImage.Vi, label: 'Việt Nam' },
  { value: languageImage.Ja, label: 'Japan' },
]

export const typeOptionsEdit = [
  { value: '', label: 'Choose image type' },
  { value: imageType.BackgroundLuckyBanner, label: 'Background Lucky Banner' },
  { value: imageType.BackgroundLuckyPool, label: 'Background Lucky Pool' },
  { value: imageType.BackgroundSlider, label: 'Background Slider' },
  { value: imageType.BackgroundSliderPackages, label: 'Background Slider Packages' },
  { value: imageType.MallBackgroundSlider, label: 'Mall Background Slider' },
  { value: imageType.MallBackgroundSliderPackages, label: 'Mall Background Slider Packages' },
]

export const NftTypes = [
  { title: NftType.All, value: NftType.All },
  { title: NftType.CashBackNft, value: NftType.CashBackNft },
  { title: NftType.MoongleFarmNft, value: NftType.MoongleFarmNft },
]

export const NftStatusList = [
  { title: NftStatus.All, value: NftStatus.All },
  { title: NftStatus.Issued, value: NftStatus.Issued },
  { title: NftStatus.Active, value: NftStatus.Active },
  { title: NftStatus.Inactive, value: NftStatus.Inactive },
  { title: NftStatus.Burnt, value: NftStatus.Burnt },
]

export const PASSES = {
  MONTHLY_PASS: 'MONTHLY_PASS',
  YEARLY_PASS: 'YEARLY_PASS',
  MONTHLY_UPGRADE: 'MONTHLY_UPGRADE',
}
export const SUBSCRIPTION_PASS_TYPES = {
  Subscription: 'Subscription',
  SubscriptionLucky: 'SubscriptionLucky',
}

export const NETWORK_TYPES = {
  MATRIX: 'MATRIX',
  BINARY: 'BINARY',
}

export const STATISTICS_TRANSACTION_TYPE = {
  SUBSCRIPTION_PASS: 'Subscription Pass',
  INVESTMENT_PACKAGE: 'Investment Package',
  LEADERSHIP_MATCHING_BONUS: 'Leadership Matching Bonus',
  BINARY_BONUS: 'Binary Bonus',
  PACKAGE_ANNUAL_BONUS: 'Package Annual Bonus',
  DIRECT_BONUS: 'DirectBonus',
  MATRIX_DIRECT_BONUS: 'Matrix Direct Bonus',
  MATRIX_INDIRECT_DIRECT_BONUS: 'Matrix Indirect Bonus',
  LOTTERY_TICKET: 'Lottery Ticket',
  CAR_BONUS: 'Car Bonus',
  RANKING_BONUS: 'Ranking Bonus',
  MATRIX_BONUS: 'Matrix Bonus',
  MONTHLY_GLOBAL_BONUS: 'Monthly Global Bonus',
}

export const PAYMENT_METHOD = [
  { title: 'VMM', value: 'Vmm' },
  { title: 'VPC', value: 'Vpc' },
  { title: 'USDT', value: 'Usdt' },
  { title: 'USDC', value: 'Usdc' },
  { title: 'VLG', value: 'Vlg' },
  { title: 'VUSD', value: 'Vusd' },
  { title: 'CREDIT CARD', value: 'CreditCard' },
]

export const FEE_TYPE = [
  { title: 'Purchase Subscription', value: 'PurchaseSubscription' },
  { title: 'Purchase Package', value: 'PurchasePackage' },
  { title: 'Transfer To Internal', value: 'TransferToInternal' },
  { title: 'Transfer To VlinkExchange', value: 'TransferToVlinkExchange' },
  { title: 'Transfer To VlinkPay', value: 'TransferToVlinkPay' },
  { title: 'Transfer To VMMCB', value: 'TransferToVMMCB' },
]

export enum FeeConfigStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum POPUP_PACKAGE_TYPE {
  EditPackage = 'EditPackage',
  EditPayment = 'EditPayment',
}

export const MENU_MANAGEMENT_PACKAGE = [
  {
    label: 'Packages',
    value: 'Packages',
    tab: 'packages-setting',
  },
  {
    label: 'Payment Methods',
    value: 'PaymentMethods',
    link: 'payment-method',
  },

  // {
  //   label: 'Payout Methods',
  //   value: 'PayoutMethods',
  //   link: 'payout',
  // },
  //   {
  //     label: 'Overview',
  //     value: 'Overview',
  //     link: 'overview',
  //   },
]

export const MENU_PACKAGE_POPUP = [
  {
    label: 'Single',
    value: 'SingleMethod',
  },
  {
    label: 'Combine',
    value: 'CustomMethod',
  },

  {
    label: 'Vlinkpay',
    value: 'VlinkPay',
  },
  {
    label: 'Trc20',
    value: 'Trc20',
  },
]

export const PAYMENT_STATUS = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Active',
    value: 'ACTIVE',
  },
  {
    text: 'Inactive',
    value: 'INACTIVE',
  },
]

export const PaymentMethod = {
  All: '',
  Single: 'SingleMethod',
  Combine: 'CustomMethod',
  Trc20: 'Trc20',
  VlinkPay: 'Vlinkpay',
}

export const TYPE_PAYMENT_METHOD = [
  {
    text: 'All',
    value: PaymentMethod.All,
  },
  {
    text: 'Single',
    value: PaymentMethod.Single,
  },
  {
    text: 'Combine',
    value: PaymentMethod.Combine,
  },
  {
    text: 'Trc20',
    value: PaymentMethod.Trc20,
  },
  {
    text: 'Vlinkpay',
    value: PaymentMethod.VlinkPay,
  },
]

export const StatusPackage = {
  ACTIVE: true,
  INACTIVE: false,
}

export const TypePaymentMethod = {
  SingleMethod: 'Single',
  CustomMethod: 'Custom',
  Trc20: 'Trc20',
  VlinkPay: 'Vlinkpay',
}
export enum PackageSettingGroup {
  ALL = '',
  InvestmentPackage = 'InvestmentPackage',
  SubscriptionPass = 'SubscriptionPass',
}

export const listNamePackageGroup = [
  { value: PackageSettingGroup.InvestmentPackage, name: 'INVESTMENT PACKAGE' },
  { value: PackageSettingGroup.SubscriptionPass, name: 'SUBSCRIPTION PASS' },
]

export const PACKAGE_STATUS = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Active',
    value: 'true',
  },
  {
    text: 'Block',
    value: 'false',
  },
]

export const PAYMENT_OPTIONS = [
  {
    text: 'All',
    value: PackageSettingGroup.ALL,
  },
  {
    text: 'Investment Package',
    value: PackageSettingGroup.InvestmentPackage,
  },
  {
    text: 'Subscription Pass',
    value: PackageSettingGroup.SubscriptionPass,
  },
]
export const PAYMENT_METHODS = {
  SINGLE: 'SingleMethod',
  COMBINE: 'CustomMethod',
}
export const PAYMENT_METHOD_TYPE = [
  { text: 'Single', value: PAYMENT_METHODS.SINGLE },
  { text: 'Combine', value: PAYMENT_METHODS.COMBINE },
]
