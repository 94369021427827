import React, { Suspense, useContext, useEffect, useState } from 'react'
import { AuthContext } from 'contexts/AuthContexts'
import Loader from 'components/loader'
import Header from 'components/header'
import Sidebar from 'components/sidebar'
import Footer from 'components/footer'
import { ERROR_401_EVENT, ERROR_403_EVENT } from 'helpers/constants'
import PubSub from 'pubsub-js'
import { Navigate } from 'react-router-dom'

type Props = {
  children?: React.ReactNode
  roles: Array<string>
}

const AuthLayout = (props: Props) => {
  const { user, handelSignOutCallback } = useContext(AuthContext)
  var { children, roles } = props
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  useEffect(() => {
    PubSub.subscribe(ERROR_401_EVENT, async () => {
      await handelSignOutCallback()
    })

    PubSub.subscribe(ERROR_403_EVENT, async () => {
      window.location.replace('/403')
    })
    const noHasInfor = !user || !user.profile
    setIsAuthenticated(!noHasInfor)
    return () => {
      PubSub.unsubscribe(ERROR_401_EVENT)
    }
  }, [handelSignOutCallback, roles, user])

  return isAuthenticated ? (
    roles.length > 0 && !roles.includes(user.profile.role) ? (
      <Navigate to="/403" replace={true} />
    ) : (
      <Suspense fallback={<Loader />}>
        <Header />
        <Sidebar />
        <main className="main mainheight bg-transparent shadow-none">{children}</main>
        <Footer />
      </Suspense>
    )
  ) : null
}

export default AuthLayout
