import OpenAlert from 'components/sweetAlert'
import { PaymentMethodOption, PaymentMethodOptionDetails } from 'models'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
interface PropsType {
  onCancel: any
  onSubmit: Function
  wallets: any
  edit?: PaymentMethodOption | undefined
  onStatusChange?: Function
  statusData: boolean
}

function FormAddSinglePaymentMethod(props: PropsType) {
  const { onSubmit, wallets, onCancel, edit, statusData } = props
  const [status, setStatus] = useState<boolean>(statusData)
  const [paymentMethodOptionDetailsList, setPaymentMethodOptionDetailsList] = useState<
    PaymentMethodOptionDetails[]
  >([{ tokenItemId: wallets[0]?.id, percentage: 100, isFixedPercentage: true }])
  const [paymentMethodOption, setPaymentMethodOption] = useState<PaymentMethodOption>({
    name: '',
    type: 'SingleMethod',
    paymentMethodOptionDetails: paymentMethodOptionDetailsList,
  })
  const [selectedWallet, setSelectedWallet] = useState<string>()
  function handleChangeStatus(e: any) {
    const newStatus = e.target.checked
    setStatus(newStatus)
    if (props.onStatusChange) {
      props.onStatusChange(newStatus)
    }
  }
  const handleSelectChange = (event: any) => {
    setSelectedWallet(event.target.value)
    paymentMethodOption.paymentMethodOptionDetails[0].tokenItemId = event.target.value
  }

  const handleSubmit = async (e: any) => {
    if (!paymentMethodOption.name) {
      OpenAlert('Please enter payment method name!', '', 'warning')
      return
    }
    onSubmit(paymentMethodOption)
  }

  const handleSelectWallet = (walletName: string) => {
    if (walletName) {
      walletName = walletName.replace('_WALLET', '')
      walletName = walletName.split('_').join(' ')
      return walletName
    }
  }
  useEffect(() => {
    if (edit) {
      setPaymentMethodOption({
        name: edit.name,
        type: edit.type,
        paymentMethodOptionDetails: edit.paymentMethodOptionDetails,
      })

      setSelectedWallet(edit.paymentMethodOptionDetails[0]?.tokenItemId)
      setPaymentMethodOptionDetailsList(edit.paymentMethodOptionDetails)
    }
  }, [edit])
  return (
    <>
      <Modal.Body>
        <form className="myfrm">
          <div className="row gy-2 gx-2">
            <div className="col-12">
              <label className="mb-1">Status</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="lockoutEnabled"
                  checked={status}
                  onChange={handleChangeStatus}
                />

                <label
                  className="form-check-label text-uppercase fw-bold"
                  htmlFor="lockoutEnabled"
                  style={{ color: `${status ? 'blue' : 'red'}` }}
                >
                  {status ? 'Ative' : 'Inactive'}
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="mb-1">
                  Name <span className="text-danger">*</span>
                </label>
                <div className="border mb-3 rounded">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <input
                        className="form-control fw-bold"
                        type="text"
                        name="name"
                        value={paymentMethodOption.name}
                        onChange={(event: any) => {
                          setPaymentMethodOption({
                            ...paymentMethodOption,
                            name: event.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h5 className="fw-bold">Payment token</h5>
              <div>
                <div className="payout-wallet-item mb-4">
                  <div className="row gx-1">
                    <label htmlFor="selectUser">
                      Token <span className="text-danger">*</span>
                    </label>
                    <div className="mb-2 mt-1">
                      <select
                        className="form-select border w-100 fw-bold"
                        value={selectedWallet}
                        onChange={handleSelectChange}
                        name="name"
                      >
                        {wallets?.map((item: any) => (
                          <option value={item.id} key={item.id}>
                            {handleSelectWallet(item.name)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row gx-1">
                    <label className="mb-1">
                      Percent (%) <span className="text-danger">*</span>
                    </label>
                    <div className="border mb-3 rounded">
                      <div className="row align-items-center gx-0">
                        <div className="col">
                          <input
                            className="form-control "
                            type="text"
                            value={100}
                            name="value"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="">
        <div className="row w-100 align-items-center justify-content-center">
          <div className="col-auto">
            <Button
              variant="secondary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Cancel'}
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              variant="primary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Submit'}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

export default FormAddSinglePaymentMethod
