import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import OpenAlert from './sweetAlert'

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
})

function FormAddCombinePaymentMethod(props: any) {
  const { onSubmit, wallets, onCancel, data, edit } = props
  const formik = useFormik({
    initialValues: {
      name: '',
      walletType: 'CustomMethod',
      status: true,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (checkWalletValid(paymentWallets)) {
        onSubmit({
          formValues: values,
          paymentWallets: paymentWallets,
        })
        setIsCheckSubmid(false)
      }
    },
  })

  const initData = {
    randomId: uuidv4(),
    idWallet: '',
    value: 0.0,
    requiredPercent: false,
    fixed: false,
  }
  const [paymentWallets, setpaymentWallets] = useState([initData])
  const [isWalletRequired, setIsWalletRequired] = useState(false)
  const [inputValue, setInputValue] = useState<any>(0)
  const [errorIds, setErrorIds] = useState<string[]>([])
  const [isCheckSubmid, setIsCheckSubmid] = useState<boolean>(false)
  const handleFocus = () => {
    setInputValue('')
  }
  const handleAddWalletPayment = () => {
    const initNewData = {
      randomId: uuidv4(),
      idWallet: '',
      walletType: 'CustomMethod',
      requiredPercent: false,
      fixed: false,
      value: 0.0,
    }
    setpaymentWallets([...paymentWallets, initNewData])
  }
  const handleRemovePaymentWallet = (id: string) => {
    const newData = paymentWallets.filter((item) => item.randomId !== id)
    setpaymentWallets([...newData])
  }
  const checkExist = (id: string) => {
    return paymentWallets.some((obj) => obj.idWallet === id)
  }

  const alertWarings = (conten: string) => {
    OpenAlert(conten, '', 'warning')
  }
  const checkWalletValid = (paymentWallets: any) => {
    const isWalletEmpty = paymentWallets.some((wallet: any) => wallet.idWallet.trim() === '')

    const totalValueFixed = paymentWallets.reduce((total: number, obj: any) => {
      if (obj.fixed) return total + obj.value
      return total
    }, 0)

    const countWalletFixed0Percent = paymentWallets.reduce((count: number, obj: any) => {
      if (obj.fixed && obj.value === 0) return count + 1
      return count
    }, 0)

    const countWalletNotFixed = paymentWallets.reduce((count: number, obj: any) => {
      if (!obj.fixed) {
        return count + 1
      }
      return count
    }, 0)
    isIdMethodNull(paymentWallets)
    setIsCheckSubmid(true)
    if (isWalletEmpty) {
      OpenAlert('Please select a wallet for each item.', '', 'warning')
      return false
    }
    if (countWalletFixed0Percent > 0) {
      alertWarings('Tokens with fixed percentage attributes must have values greater than 0')
      return false
    }
    if (totalValueFixed > 100) {
      alertWarings('The total value of tokens with fixed percentage attributes must not exceed 100')
      return false
    }
    if (totalValueFixed < 100 && countWalletNotFixed === 0) {
      alertWarings(
        'The total value of tokens with fixed percentage attributes must equal 100, or additional tokens should be added without fixed percentage attributes.',
      )
      return false
    }
    return true
  }

  function isIdMethodNull(paymentMethodList: any[]) {
    const emptyRandomIds: string[] = paymentMethodList
      .filter((wallet: any) => wallet.idWallet.trim() === '')
      .map((wallet: any) => wallet.randomId)
    setErrorIds(emptyRandomIds)
    return emptyRandomIds.length > 0
  }

  useEffect(() => {
    if (edit) {
      const paymentWalletsData = edit.paymentMethodOptionDetails.map((wallet: any) => ({
        ...wallet,
        requiredPercent: wallet.percentage !== 0,
        fixed: wallet.isFixedPercentage,
        value: wallet.percentage,
        idWallet: wallet.tokenItemId,
        randomId: wallet.id || uuidv4(),
      }))
      setpaymentWallets(paymentWalletsData)
    }
  }, [edit])

  useEffect(() => {
    const updatePaymentMethodInfo = () => {
      if (data) {
        formik.setValues({
          name: data.name,
          status: data.status,
          walletType: 'CustomMethod',
        })
      }
    }
    updatePaymentMethodInfo()
  }, [data])

  const handleChangeMethod = (id: string, e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setpaymentWallets(
      paymentWallets.map((w) => (w.randomId === id ? { ...w, idWallet: value } : w)),
    )
    if (isCheckSubmid && value !== undefined) {
      setErrorIds((prevIds) => prevIds.filter((itemId) => itemId !== id))
    }
  }

  return (
    <form className="myfrm" onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <div className="row gy-2 gx-2">
          <div className="col-12">
            <label className="mb-1">Status</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="lockoutEnabled"
                checked={formik.values.status}
                name="status"
                onChange={formik.handleChange}
              />
              <label
                className="form-check-label text-uppercase fw-bold"
                htmlFor="lockoutEnabled"
                style={{ color: `${formik.values.status ? 'blue' : 'red'}` }}
              >
                {formik.values.status ? 'Ative' : 'Inactive'}
              </label>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-3">
              <label className="mb-1">
                Name <span className="text-danger">*</span>
              </label>
              <div className="border mb-3 rounded">
                <div className="row align-items-center gx-0">
                  <div className="col">
                    <input
                      className="form-control "
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              {formik.errors && <p className="error-msg">{formik.errors.name}</p>}
            </div>
          </div>
          <div className="col-12">
            <h6>Wallets</h6>
            <div>
              {paymentWallets.map((wallet, index) => (
                <div
                  key={wallet.randomId}
                  className={`payout-wallet-item mb-4 ${
                    errorIds.includes(wallet.randomId) ? 'border border-danger' : ''
                  }`}
                >
                  {index >= 0 && (
                    <button
                      type="button"
                      className="btn btn-primary iconbutton"
                      onClick={() => handleRemovePaymentWallet(wallet.randomId)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  )}

                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <label>
                        Token <span className="text-danger">*</span>
                      </label>
                      <div className="d-inline form-switch float-end ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={wallet.requiredPercent === true}
                          onChange={(event) => {
                            setpaymentWallets(
                              paymentWallets.map((w) =>
                                w.randomId === wallet.randomId
                                  ? { ...w, requiredPercent: Boolean(event.target.checked) }
                                  : w,
                              ),
                            )
                          }}
                        />
                      </div>
                      <label className="d-inline float-end pe-2 "> Require minimum</label>
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 mt-1">
                      <select
                        className="form-select border w-100"
                        value={wallet.idWallet}
                        name="selectedWallet"
                        onChange={(e: any) => handleChangeMethod(wallet.randomId, e)}
                      >
                        <option disabled value={''}>
                          Select a wallet
                        </option>
                        {wallets?.map((item: any) => (
                          <option value={item.id} key={item.id} disabled={checkExist(item.id)}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ display: wallet.requiredPercent ? 'block' : 'none' }}
                  >
                    <div className="col-md-12 mb-2">
                      <label>
                        Percent <span className="text-danger">*</span>
                      </label>
                      <div className="d-inline form-switch float-end ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={wallet.fixed === true}
                          onChange={(event) => {
                            setpaymentWallets(
                              paymentWallets.map((w) =>
                                w.randomId === wallet.randomId
                                  ? { ...w, fixed: Boolean(event.target.checked) }
                                  : w,
                              ),
                            )
                          }}
                        />
                      </div>
                      <label className="d-inline float-end pe-2 "> Fixed percent</label>
                    </div>
                    <div className="col-md-12">
                      <div className="border mb-3 rounded">
                        <div className="row align-items-center gx-0">
                          <div className="col">
                            <input
                              type="number"
                              className="form-control"
                              value={wallet.value !== 0 ? wallet.value : inputValue}
                              min={0}
                              max={100}
                              name="value"
                              step={0.01}
                              onFocus={handleFocus}
                              onChange={(event) => {
                                setpaymentWallets(
                                  paymentWallets.map((w) =>
                                    w.randomId === wallet.randomId
                                      ? { ...w, value: Math.abs(parseFloat(event.target.value)) }
                                      : w,
                                  ),
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {isWalletRequired && (
              <div className="error-msg">Please select a wallet for each item.</div>
            )}
            <button type="button" className="btn btn-md btn-link" onClick={handleAddWalletPayment}>
              <i className="bi bi-plus"></i> Add wallet
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="">
        <div className="row w-100 align-items-center justify-content-center">
          <div className="col-auto">
            <Button
              variant="secondary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Cancel'}
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              variant="primary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </form>
  )
}

export default FormAddCombinePaymentMethod
