import { PACKAGE_STATUS, PAYMENT_OPTIONS } from 'helpers/constants'
import { ModePackageFitter } from 'models'
import React, { useEffect, useState } from 'react'

interface PropsType {
  filter: ModePackageFitter
  onFilter: Function
}
const FilterPackage = ({ onFilter, filter }: PropsType) => {
  const [filterValue, setFilterValue] = useState<ModePackageFitter>(filter)
  const handleSubmit = (e: any) => {
    e.preventDefault()
    onFilter(filter)
  }

  useEffect(() => {
    setFilterValue(filter)
  }, [filter])
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="card border-0 rounded" style={{ minWidth: '100%' }}>
          <div className="card-header rounded d-inline-block">
            <div className="row gx-1 align-items-end justify-content-end">
              <div className="col-md-4 mb-2">
                <label htmlFor="selectUser" className="lbl mb-1">
                  Search
                </label>
                <input
                  className="form-control border"
                  placeholder="Enter package name"
                  type="text"
                  onChange={(filterChange: any) => {
                    setFilterValue({
                      ...filterValue,
                      packageName: filterChange.target.value,
                    })
                  }}
                />
              </div>
              <div className="col-md-2">
                <div className="row gx-1">
                  <div className="col-md mb-2">
                    <label className="lbl mb-1">Status</label>
                    <div className="d-flex align-items-center">
                      <div className="col">
                        <select
                          className="form-select border w-100"
                          onChange={(filterChange: any) => {
                            setFilterValue({
                              ...filterValue,
                              status: filterChange.target.value,
                            })
                          }}
                        >
                          {PACKAGE_STATUS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="row gx-1">
                  <div className="col-md mb-2">
                    <label className="lbl mb-1">Group</label>
                    <div className="d-flex align-items-center">
                      <div className="col">
                        <select
                          className="form-select border w-100"
                          onChange={(filterChange: any) => {
                            setFilterValue({
                              ...filterValue,
                              group: filterChange.target.value,
                            })
                          }}
                        >
                          {PAYMENT_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto mb-2">
                <button
                  className="btn btn-primary btn-md border-0 px-4"
                  onClick={() => {
                    onFilter(filterValue)
                  }}
                >
                  <small>Search</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default FilterPackage
