import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MENU_MANAGEMENT_PACKAGE } from 'helpers/constants'
import { Link } from 'react-router-dom'
import PackagesSettingComponent from 'components/packages/packages-setting'
import PaymentMethodComponent from 'components/packages/payment-method'
import { MenuItem, TabContentComponentsType } from 'models'
import { changeTab } from 'store/slice/packagesSlice'
import { RootState } from 'store'

function PackagesManagement() {
  const activeTab = useSelector((store: RootState) => store.package.activeTab)
  const dispatch = useDispatch()

  const handleClick = (item: MenuItem, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    dispatch(changeTab(item))
  }

  const tabContentComponents: TabContentComponentsType = {
    Packages: <PackagesSettingComponent />,
    PaymentMethods: <PaymentMethodComponent />,
  }

  return (
    <>
      <div className="container-fluid ">
        <div className="row align-items-center page-title">
          <div className="col-12 col-md mb-2 mb-sm-0">
            <h3 className="mb-0">{activeTab.label}</h3>
          </div>
        </div>
        <hr className="p-0 m-0 mb-4" />
      </div>

      <div className="container-fluid">
        <ul className="nav nav-tabs justify-content-center nav-WinDOORS nav-lg mb-4 mt-4">
          {MENU_MANAGEMENT_PACKAGE.map((item: MenuItem) => (
            <li className="nav-item" key={item.value}>
              <Link
                className={`nav-link ${activeTab.value === item.value ? 'active' : ''}`}
                to="#"
                onClick={(e) => handleClick(item, e)}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
        {tabContentComponents[activeTab.value]}
        <div className="pt-2"></div>
      </div>
    </>
  )
}

export default PackagesManagement
