import ModalLogin from 'components/modal-login'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LOGIN_ROUTE } from 'helpers/constants'

function Page403() {
  const navigate = useNavigate()
  const onUserUnloaded = () => {
    localStorage.clear()
    navigate(LOGIN_ROUTE)
  }

  return (
    <>
      <img
        src="/assets/img/mascot/bg-bee.jpg"
        alt="MOONGLE FARM"
        width={'100%'}
        height={'100%'}
        className="img-err"
      />
      <div className="position-absolute start-0 end-0 text-center" style={{ top: '83%' }}>
        <h3 className="text-warning mt-3">Access Denied</h3>
        <p>
          <button
            className="btn btn-link bg-gradient-theme-light theme-blue"
            onClick={onUserUnloaded}
          >
            Login
          </button>
        </p>
      </div>
    </>
  )
}

export default Page403
