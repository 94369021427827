import { IPopupPackageModel, MenuItem, PackageDtoModel } from 'models'
import {
  GUID_EMPTY,
  MENU_MANAGEMENT_PACKAGE,
  MENU_PACKAGE_POPUP,
  POPUP_PACKAGE_TYPE,
} from 'helpers/constants'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface PackageState {
  activeTab: MenuItem
  packages: PackageDtoModel
  popup: IPopupPackageModel
  activeTabPopUp: MenuItem
}

const initialState: PackageState = {
  activeTab: MENU_MANAGEMENT_PACKAGE[0] as MenuItem,
  packages: {
    id: GUID_EMPTY,
    name: '',
    price: 0,
    status: '',
    description: '',
    imagePath: '',
    packageSettingGroup: '',
    rowSpan: 0,
  },
  popup: {
    type: '',
    status: false,
  },
  activeTabPopUp: MENU_PACKAGE_POPUP[0] as MenuItem,
}

const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<MenuItem>) => {
      state.activeTab = action.payload
    },
    editPackage: (state, action: PayloadAction<PackageDtoModel>) => {},
    changePopup: (state, action: PayloadAction<boolean>) => {
      state.popup.status = action.payload
    },
    editPaymentMethod: (state, action: PayloadAction<PackageDtoModel>) => {
      state.activeTabPopUp =
        state.packages.id === action.payload.id
          ? state.activeTabPopUp
          : (MENU_PACKAGE_POPUP[0] as MenuItem)
      state.packages = action.payload
      state.popup.type = POPUP_PACKAGE_TYPE.EditPayment
      state.popup.status = true
    },
    changeTabPopup: (state, action: PayloadAction<MenuItem>) => {
      state.activeTabPopUp = action.payload
    },
  },
})

export const { changeTab, editPackage, editPaymentMethod, changeTabPopup, changePopup } =
  packageSlice.actions

export default packageSlice.reducer
