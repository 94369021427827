import { PAYMENT_METHOD_TYPE } from 'helpers/constants'
import React from 'react'
import { Modal } from 'react-bootstrap'

function FormAddNewPaymentMethod(props: any) {
  const { onSubmit } = props
  const handleClick = (value: any) => {
    onSubmit(value)
  }
  return (
    <>
      <Modal.Body>
        <form className="myfrm">
          <p>
            <b>Select a type(*)</b>
          </p>
          <div className="row">
            {PAYMENT_METHOD_TYPE.map((item) => (
              <div key={item.value} className="col-md-6 mt-2">
                <button
                  type="button"
                  className="btn w-100 btn-select-type"
                  onClick={() => handleClick(item.value)}
                >
                  {item.text}
                </button>
              </div>
            ))}
          </div>
        </form>
      </Modal.Body>
    </>
  )
}

export default FormAddNewPaymentMethod
