import { LOADING, TOKEN_ADMIN } from 'helpers/constants'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import jwtDecode from 'jwt-decode'
import Loader from './loader'

const ModalLogin = (props: any) => {
  const { isShowModal, onCloseModal } = props
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const initValues = {
    username: '',
    password: '',
  }
  useEffect(() => {
    setShowModal(isShowModal)
  }, [isShowModal])

  const [inforUser, setInforUser] = useState(initValues)

  const closeModal = () => {
    setShowModal(false)
    onCloseModal()
  }
  const validateName = (item: any) => {
    if (item === '') return false
    return true
  }

  const decodedToken = (jwtToken: string) => {
    if (!jwtToken) return null
    const decodedToken = jwtDecode(jwtToken)
    return decodedToken
  }

  const handleSubmit = async () => {
    setIsSubmit(true)
    if (!validateName(inforUser.username) || !validateName(inforUser.password)) {
      return
    }
    setIsLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setIsLoading(data)
      setInforUser(initValues)
      setIsSubmit(false)
    })
    const res: any = await axiosClient().post(END_POINTS.USER_API_END_POINTS.LOGIN(), inforUser)
    if (res) {
      const token = res.data.access_token
      const mydata = res.data
      await localStorage.setItem(TOKEN_ADMIN, token)
      const infor: any = decodedToken(token)
      const authHeader = token ? `Bearer ${token}` : ''
      const acceptLang = 'en-US'
      const query = await axiosClient().get(
        END_POINTS.USER_API_END_POINTS.USERS_PROFILE(infor.sub),
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': acceptLang,
            Authorization: authHeader,
          },
        },
      )
      const objectData = {
        access_token: token,
        expires_at: mydata.expires_in,
        token_type: mydata.token_type,
        scope: `${infor.scope[2]} ${infor.scope[1]} ${infor.scope[0]} ${infor.scope[3]}`,
        refresh_token: mydata.refresh_token,
        profile: {
          email: infor.email,
          idp: infor.idp,
          role: query.data.roles[0],
          sub: infor.sub,
          sid: infor.jti,
        },
      }

      await localStorage.setItem(
        `oidc.user:${infor.iss}:${infor.client_id}`,
        JSON.stringify(objectData),
      )

      window.location.replace('/')
    }
  }
  return (
    <div>
      <Modal show={showModal} onHide={closeModal} className="modal-user" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="modal-user__title">Sign in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader></Loader>}
          <form onSubmit={handleSubmit} className={`${isSubmit && ' was-validated'} `}></form>
          <div
            className={`form-group mt-3 position-relative  ${
              validateName(inforUser.username) && ' is-valid'
            }`}
          >
            <label>
              Username(*){' '}
              {isSubmit && !validateName(inforUser.username) && (
                <small className="text-danger">Required</small>
              )}
            </label>
            <input
              type="text"
              placeholder="Username"
              className="form-control"
              value={inforUser.username}
              onChange={(event) => {
                setIsSubmit(false)
                setInforUser({ ...inforUser, username: event.target.value })
              }}
              required
            />
          </div>

          <div
            className={`form-group mt-3 position-relative  ${
              validateName(inforUser.password) && ' is-valid'
            }`}
          >
            <label>
              Password(*){' '}
              {isSubmit && !validateName(inforUser.password) && (
                <small className="text-danger">Required</small>
              )}
            </label>
            <div className="position-relative">
              <input
                type={isShowPassword ? 'text' : 'password'}
                placeholder="Password"
                className="form-control"
                value={inforUser.password}
                onChange={(event) => {
                  setIsSubmit(false)
                  setInforUser({ ...inforUser, password: event.target.value })
                }}
                required
              />
              <div className="icon" onClick={() => setIsShowPassword(!isShowPassword)}>
                {!isShowPassword && <i className="bi bi-eye-slash"></i>}
                {isShowPassword && <i className="bi bi-eye"></i>}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            className="btn btn-primary btn-style-blue w-100"
            disabled={isLoading}
          >
            Sign in
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ModalLogin
