import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
let OidcConfig: UserManagerSettings = null as any

if (typeof window !== 'undefined') {
  const portalUrl = window.location.origin
  const AUTH_URL = process.env.REACT_APP_AUTH_URL
  const AUTH_CLIENT = process.env.REACT_APP_AUTH_CLIENT
  const AUTH_SCOPE = process.env.REACT_APP_AUTH_SCOPE

  OidcConfig = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: AUTH_URL!,
    client_id: AUTH_CLIENT!,
    redirect_uri: `${portalUrl}/login-callback`,
    response_type: `code`,
    scope: AUTH_SCOPE,
    post_logout_redirect_uri: `${portalUrl}`,
    silent_redirect_uri: `${portalUrl}/auth/silent-renew`,
    automaticSilentRenew: true,
  }
}

export { OidcConfig }
