import { listNamePackageGroup } from 'helpers/constants'
import { PackageDtoModel } from 'models'
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { editPaymentMethod } from 'store/slice/packagesSlice'
interface TypeProps {
  data: PackageDtoModel[] | undefined
}

function ListManagePackage(props: TypeProps) {
  const dispatch = useDispatch()
  const { data } = props
  const getGroupName = (groupId?: string) => {
    const group = listNamePackageGroup.find((group) => group.value === groupId)
    return group ? group.name : 'Unknown Group'
  }

  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr className="text-muted">
          <th style={{ maxWidth: '500px' }}>Group</th>
          <th>Name</th>
          <th className="text-end">Price</th>
          <th className="text-center">Status</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((_package) => (
          <tr key={_package.id}>
            {_package.rowSpan! > 0 && (
              <td
                className="text-uppercase"
                rowSpan={_package?.rowSpan}
                style={{
                  backgroundColor: '#F7F8FA',
                  borderRight: '1px solid #cccccc87',
                }}
              >
                {getGroupName(_package?.packageSettingGroup)}
              </td>
            )}
            <td>
              <strong className="text-uppercase">{_package.name}</strong>
            </td>
            <td className="text-end">
              <strong>${_package.price}</strong>
            </td>
            <td className="text-center">
              <Badge bg={_package.status ? 'success' : 'secondary'} style={{ fontSize: '11px' }}>
                {_package.status ? 'Active' : 'Block'}
              </Badge>
            </td>
            <td className="text-center">
              <Button
                variant="info"
                size="sm"
                onClick={() => dispatch(editPaymentMethod(_package))}
              >
                <img src="/assets/img/packs/ph_hand.svg" alt="Edit" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ListManagePackage
