import { createAxiosInstance } from './interceptors'
import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { TOKEN_ADMIN } from 'helpers/constants'

let client: AxiosInstance = null as any

export const axiosClient = () => {
  const acceptLang = 'en-US'
  const token = localStorage.getItem(TOKEN_ADMIN)
  const authHeader = token ? `Bearer ${token}` : ''

  if (!client) {
    client = createAxiosInstance({
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': acceptLang,
        Authorization: authHeader,
      },
    })
  }

  return {
    get: (url: string, options: AxiosRequestConfig = {}) => client.get(url, { ...options }),
    post: (url: string, data: any, options: AxiosRequestConfig = {}) =>
      client.post(url, data, { ...options }),
    put: (url: string, data: any, options: AxiosRequestConfig = {}) =>
      client.put(url, data, { ...options }),
    delete: (url: string, options: AxiosRequestConfig = {}) => client.delete(url, { ...options }),
  }
}

export { default as END_POINT } from './end-points'
