const API_BASE_URL = process.env.REACT_APP_API_HOST
const ACCOUNT = {
  LOGIN_BY_ADMIN: (id: string) => `${API_BASE_URL}/Account/${id}/tokens`,
}
const USER_API_END_POINTS = {
  USERS: () => `${API_BASE_URL}/Users`,
  USERS_PROFILE: (id: string) => `${API_BASE_URL}/Users/${id}`,
  USERS_PASSES: (id: string) => `${API_BASE_URL}/UserProfile/${id}/passes`,
  USERS_SEARCH: () => `${API_BASE_URL}/UserProfile/search-autocomplete`,
  SET_ATTRIBUTE: (id: string, action: string | null) =>
    `${API_BASE_URL}/Users/${id}/set-attribute/${action}`,
  IS_EXIST_USERNAME: (username: string) => `${API_BASE_URL}/Users/is-exits-username/${username}`,
  CHANGE_USERNAME: () => `${API_BASE_URL}/Users/change-username`,
  IS_EXIST_EMAIL: (email: string) => `${API_BASE_URL}/Users/is-exist-email/${email}`,
  CHANGE_EMAIL: () => `${API_BASE_URL}/Users/change-email`,
  NETWORK_TREE_INFOR: (id: string) => `${API_BASE_URL}/UserProfile/${id}/network-tree-info`,
  MY_B_NETWORK_TREE: (NodeId: number, LevelLimit: number, UserId: string) =>
    `${API_BASE_URL}/Users/my-b-network-tree?NodeId=${NodeId}&LevelLimit=${LevelLimit}&&UserId=${UserId}`,
  ADD_USERS: () => `${API_BASE_URL}/Users/add-users`,
  ADD_USER: () => `${API_BASE_URL}/Users/add-user`,
  SEARCH_CONTAIN: (username: string) =>
    `${API_BASE_URL}/Users/search?SearchKey=${username}&Comparison=StartWith`,
  SEARCH_EXACT: (username: string) =>
    `${API_BASE_URL}/Users/search?SearchKey=${username}&Comparison=Exact`,
  ROLES: () => `${API_BASE_URL}/Users/roles`,
  USERS_MANAGEMENT: () => `${API_BASE_URL}/Users/user-management`,
  LOGOUT: () => `${API_BASE_URL}/Account/logout`,
  LOGIN: () => `${API_BASE_URL}/Account/login-admin`,
  DEACTIVE_MEMBERSHIP: () => `${API_BASE_URL}/UserProfile/deactive-membership`,
}
const BONUS_MANAGERMENT = {
  DAILY_BONUS: (id: string) => `${API_BASE_URL}/DailyBonus/manage/${id}`,
  DAILY_BONUS_SEARCH: () => `${API_BASE_URL}/DailyBonus/manage/search`,
  DAILY_BONUS_GET_LIST: () => `${API_BASE_URL}/DailyBonus/get-list`,
  APPROVE: () => `${API_BASE_URL}/DailyBonus/manage/approve`,
}
const NETWORK_TREE = {
  LIST_NETWORK_VOLUME: (username: string, email: string = '') =>
    `${API_BASE_URL}/NetworkSaleVolume/manage/network-sale-volume-all?UserName=${username}`,
  MY_B_NETWORK_TREE: (NodeId: number, LevelLimit: number) =>
    `${API_BASE_URL}/NetworkTree/my-b-network-tree?NodeId=${NodeId}&LevelLimit=${LevelLimit}`,
  CHANGE_SPONSOR: () => `${API_BASE_URL}/NetworkTree/change-sponsor`,
}
const MATRIX_TREE = {
  INFOR: (UserId: string) => `${API_BASE_URL}/MatrixTree/matrix-tree-info?UserId=${UserId}`,
  TREE: (NodeId: number, LevelLimit: number, UserId: string) =>
    `${API_BASE_URL}/MatrixTree/my-b-matrix-tree?NodeId=${NodeId}&LevelLimit=${LevelLimit}&UserId=${UserId}`,
  SIMPLE_VIEW_LEVEL_DETAIL: (level: number, userId: string) =>
    `${API_BASE_URL}/MatrixTree/levels/details?Level=${level}&UserId=${userId}`,
  SIMPLE_VIEW_LEVEL_STATISTICS: (userId: string) =>
    `${API_BASE_URL}/MatrixTree/levels?UserId=${userId}`,
}
const MEMBERSHIPS = {
  INVESTMENT_PASS: () => `${API_BASE_URL}/InvestmentPackages`,
  SUBSCRIPTION_PASS: () => `${API_BASE_URL}/SubscriptionPass`,
  SUBSCRIPTION_HISTORIES: () => `${API_BASE_URL}/SubscriptionPass/histories`,
  INVESTMENT_HISTORIES: () => `${API_BASE_URL}/InvestmentPackages/histories`,
  ACTIVE_YEARLY_PASS: () => `${API_BASE_URL}/SubscriptionPass/manage/activate-subscription-pass`,
  ACTIVE_INVESTMENT_PASS: () =>
    `${API_BASE_URL}/InvestmentPackages/manage/active-investment-package`,
  UPGRADE_INVESTMENT_PASS: () => `${API_BASE_URL}/InvestmentPackages/manage/upgrade-monthly-pass`,
  RENEW_INVESTMENT_PASS: () => `${API_BASE_URL}/InvestmentPackages/manage/renew-monthly-pass`,
  PACKAGE: () => `${API_BASE_URL}/Package`,
  LIST_PACKAGES_STATISTICS: () => `${API_BASE_URL}/Package/details`,
  DETAIL_PACKAGE_STATISTICS: (id: string) => `${API_BASE_URL}/Package/${id}/detail`,
  SUBSCRIPTION_PASS_LUCKY_MONTH: () => `${API_BASE_URL}/SubscriptionPass/lucky-subscription-price`,
  SUBSCRIPTION_PASS_MONTH: (id: string) =>
    `${API_BASE_URL}/SubscriptionPass/${id}/subscription-price`,
}
const WALLETS = {
  WALLET: (userId: string) => `${API_BASE_URL}/Wallet/manage/${userId}/wallets`,
  WALLET_HISTORY: () => `${API_BASE_URL}/Wallet/manage/wallet-histories`,
  DEPOSIT: () => `${API_BASE_URL}/Wallet/manage/deposit-wallets`,
  WALLET_TOPUP_TYPE: () => `${API_BASE_URL}/AppConfig/support-deposit-wallet-types`,
  WALLET_WITHDRAW_TYPE: () => `${API_BASE_URL}/AppConfig/support-withdraw-wallet-types`,
  WITHDRAW: () => `${API_BASE_URL}/Wallet/manage/withdraw-wallets`,
  TOKEN_RATE: () => `${API_BASE_URL}/TokenRate`,
  TOKEN_CONVERSION: () => `${API_BASE_URL}/TokenRate/rate-conversion`,
  WITHDRAW_HISTORY: () => `${API_BASE_URL}/ExternalGateway/manage/search-histories`,
  WTIHDRAW_CONFIRM: () => `${API_BASE_URL}/ExternalGateway/manage/confirm-external-transfer`,
}
const RECEIPT_MANAGEMENT = {
  GET_LIST: () => `${API_BASE_URL}/Receipt/receipts`,
  APPROVE: (receiptId: string) => `${API_BASE_URL}/Receipt/${receiptId}/approved`,
  REJECT: (receiptId: string) => `${API_BASE_URL}/Receipt/${receiptId}/rejected`,
  RESUBMIT: (receiptId: string) => `${API_BASE_URL}/Receipt/${receiptId}/ask-to-resubmit`,
  RECEIPT: (receiptId: string) => `${API_BASE_URL}/Receipt/${receiptId}`,
  RECEIPT_DETAIL: (receiptId: string) => `${API_BASE_URL}/Receipt?id=${receiptId}`,
  SUMMARY: () => `${API_BASE_URL}/Receipt/summary`,
  CURRENT_CYCLE: () => `${API_BASE_URL}/Receipt/current-cycle`,
  MY_CYCLES: () => `${API_BASE_URL}/Receipt/my-cycles`,
}
const DATA = {
  GET_RANK: () => `${API_BASE_URL}/Ranking/get-ranks`,
}
const STATISTIC = {
  STATISTIC: () => `${API_BASE_URL}/Statistic`,
  TRANSACTION: () => `${API_BASE_URL}/Statistic/transactions`,
  PAYOUT: () => `${API_BASE_URL}/Statistic/payout`,
  REVENUE: () => `${API_BASE_URL}/Statistic/revenue`,
}
const TOKEN_RATE = {
  STORE_TOKEN: () => `${API_BASE_URL}/TokenRate/manage/store-token-price-daily`,
  GET_LIST: () => `${API_BASE_URL}/TokenRate/manage/get-token-price-daily-list`,
  TOKEN_RATE: () => `${API_BASE_URL}/TokenRate`,
  RATE_CONVERSION: () => `${API_BASE_URL}/TokenRate/rate-conversion`,
  UPDATE_TOKEN_PRICE: () => `${API_BASE_URL}/TokenRate/manage/update-token-price-daily`,
}

const LOTTERY = {
  LOTTERY_SUMMARY: () => `${API_BASE_URL}/Lottery/manage/lottery-summary`,
  LOTTERY_HISTORY: () => `${API_BASE_URL}/Lottery/manage/get-lottery-cycle-histories`,
  LOTTERY_UPDATE: () => `${API_BASE_URL}/Lottery/manage/edit-lottery-cycle`,
  LOTTERY_SEARCH_BUYERS: () => `${API_BASE_URL}/Lottery/manage/search-buyers-in-cycle`,
  LOTTERY_GET_NUMBER: () => `${API_BASE_URL}/Lottery/manage/get-lottery-numbers-in-cycle`,
  GENERATE_TICKET: () => `${API_BASE_URL}/Lottery/manage/generate-lottery-tickets`,
  LOTTERY_EVENTS: () => `${API_BASE_URL}/Lottery/manage/search-lottery-tickets`,
  LOTTERY_END_CYClE: () => `${API_BASE_URL}/Lottery/manage/end-current-lottery-cycle`,
  LOTTERY_CANCEL_CYClE: () => `${API_BASE_URL}/Lottery/manage/cancel-lottery-cycle`,
  LOTTERY_CYClE: (id: string) => `${API_BASE_URL}/Lottery/manage/lottery-cycles/${id}`,
  PRIZE_PLAN: () => `${API_BASE_URL}/Lottery/manage/ticket-reward-plan`,
}

const NFT = {
  NFT_GET: () => `${API_BASE_URL}/NftWallet/manage/list-nft`,
  NFT_POST: () => `${API_BASE_URL}/NftWallet/manage/transfer-nft`,
}

const APP_CONFIG = {
  LIST_SETTINGS: () => `${API_BASE_URL}/AppConfig/function-settings`,
  TOOGLE_FUNCTION_SETTING: () => `${API_BASE_URL}/AppConfig/toggle-fucntion-setting`,
  LIST_FEES: () => `${API_BASE_URL}/AppConfig/fee`,
}

const FEE_CONFIG = {
  LIST_FEES: () => `${API_BASE_URL}/FeeConfig/get-all`,
  UPDATE_FEE: () => `${API_BASE_URL}/FeeConfig/manage/update-fee-config`,
  ADD_FEE: () => `${API_BASE_URL}/FeeConfig/manage/add-fee-config`,
}

const CASH_FLOW = {
  CASH_FLOW_STATISTICS: () => `${API_BASE_URL}/CashFlow/statistics`,
  CASH_FLOW_TRANSACTIONS: () => `${API_BASE_URL}/CashFlow/transactions`,
}

const IMAGES = {
  GET_LIST_IMAGES: (type: string, lang: string) =>
    `${API_BASE_URL}/Image/get-all-image-setting?Type=${type}&Language=${lang}`,
  EDIT_IMAGE: (id: any) => `${API_BASE_URL}/Image/edit-image-setting?Id=${id}`,
  DELETE_IMAGE: (id: string) => `${API_BASE_URL}/Image/delete-image-setting?Id=${id}`,
  CREATE_IMAGE: () => `${API_BASE_URL}/Image/add-image-setting`,
}
const PACKAGE_SETTING = {
  GET_PACKAGES: () => `${API_BASE_URL}/AppConfig/all-package-setting`,
  SEARCH_PAYMENT_METHOD: () => `${API_BASE_URL}/PaymentMethod/manage/search-payment-methods`,
  GET_LIST_PAYMENT_METHOD: () => `${API_BASE_URL}/PaymentMethod/manage/payment-method-items`,
  PACKAGE_DETAIL: () => `${API_BASE_URL}/PaymentMethod/package-setting-by-id`,
  SET_PAYMENT_METHOD_TO_PAYMENT_ITEM: () =>
    `${API_BASE_URL}/PaymentMethod/manage/set-payment-methods-to-payment-item`,
  UPDATE_PACKAGE: () => `${API_BASE_URL}/`,
  GET_TAG_PACKAGE: () => `${API_BASE_URL}/`,
  PUT_PAYMENT_METHOD_STATUS: (id: string) =>
    `${API_BASE_URL}/PaymentMethod/manage/payment-methods/${id}/status`,
}

const PAYMENT = {
  GET_LIST: () => `${API_BASE_URL}/`,
  CREATE_PAYMENT_METHOD: () => `${API_BASE_URL}/PaymentMethod/manage/payment-methods`,
  UPDATE_PAYMENT_METHOD: (id: string) =>
    `${API_BASE_URL}/PaymentMethod/manage/payment-methods/${id}`,
  GET_LIST_PAYMENT_METHOD: () => `${API_BASE_URL}/PaymentMethod/manage/payment-method-items`,
  PUT_PAYMENT_METHOD_STATUS: (id: string) =>
    `${API_BASE_URL}/PaymentMethod/manage/payment-methods/${id}/status`,
}

const AZURE_STORAGE = {
  UPLOAD_ADMIN: (type: string) => `${API_BASE_URL}/AzureStorage?uploadType=${type}`,
  GET_FULL_PATH_IMAGE: (path: string) => `${API_BASE_URL}/AzureStorage/get-fullpath?path=${path}`,
}

const END_POINTS = {
  ACCOUNT,
  USER_API_END_POINTS,
  BONUS_MANAGERMENT,
  MEMBERSHIPS,
  NETWORK_TREE,
  DATA,
  WALLETS,
  RECEIPT_MANAGEMENT,
  STATISTIC,
  TOKEN_RATE,
  LOTTERY,
  NFT,
  APP_CONFIG,
  MATRIX_TREE,
  CASH_FLOW,
  FEE_CONFIG,
  PACKAGE_SETTING,
  PAYMENT,
  IMAGES,
  AZURE_STORAGE,
}

export default END_POINTS
