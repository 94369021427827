import Loader from 'components/loader'
import React, { useCallback, useEffect, useState } from 'react'
import FilterPaymentMethod from './filter-payment-method'
import END_POINTS from 'http-services/end-points'
import { axiosClient } from 'http-services'
import {
  IPaymentMethodFitter,
  IPaymentMethodItem,
  ModelPaymentMethodItem,
  PaymentMethodOption,
} from 'models'
import ListPaymentMethod from './payment-method-list'
import OpenAlert from 'components/sweetAlert'
import { PAYMENT_METHODS, StatusPackage } from 'helpers/constants'
import { Modal } from 'react-bootstrap'
import FormAddNewPaymentMethod from 'components/add-payment-method'
import FormAddSinglePaymentMethod from 'components/add-single-payment-method'
import FormAddCombinePaymentMethod from 'components/add-combine-paymen-method'
function PaymentMethodComponent() {
  const [dataTable, setDataTable] = useState<Array<IPaymentMethodItem>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [filter, setFilter] = useState<IPaymentMethodFitter>({
    type: null,
    status: null,
    searchText: null,
  })

  const actions = {
    add: 'add',
    edit: 'edit',
  }

  const [showModal, setShowModal] = useState(false)
  const [myAction, setMyAction] = useState('')
  const [editId, setEditId] = useState('')
  const [statusData, setStatusData] = useState<boolean>(true)
  const [itemEdit, setItemEdit] = useState<PaymentMethodOption>()
  const [wallets, setWallets] = useState<ModelPaymentMethodItem[]>([])
  const [selectedType, setSelectedType] = useState('')
  const onClickPaymentMethodDetail = async (_paymentmethod: any) => {
    const query = await axiosClient().get(END_POINTS.PAYMENT.GET_LIST_PAYMENT_METHOD())
    setStatusData(_paymentmethod.status)
    setWallets(query.data)
    setSelectedType(_paymentmethod.type)
    setMyAction(actions.edit)
    setItemEdit(_paymentmethod)
    setEditId(_paymentmethod.id)
    setShowModal(true)
  }

  function getStatusValue(status: string): boolean {
    return (StatusPackage as any)[status]
  }
  const handleAddPayment = async () => {
    setSelectedType('')
    setMyAction(actions.add)
    setShowModal(true)
    const query = await axiosClient().get(END_POINTS.PAYMENT.GET_LIST_PAYMENT_METHOD())
    setWallets(query.data)
  }
  const onChangePaymentMethodStatus = async (_paymentMethod: IPaymentMethodItem): Promise<void> => {
    try {
      const newStatus = getStatusValue(_paymentMethod.status) ? 'INACTIVE' : 'ACTIVE'
      const response: any = await axiosClient().put(
        END_POINTS.PACKAGE_SETTING.PUT_PAYMENT_METHOD_STATUS(_paymentMethod.id),
        {
          id: _paymentMethod.id,
          status: newStatus,
        },
      )
      if (response) {
        OpenAlert('Update Status method payment successfully!', '', 'success')
      }
      loadData()
    } catch (error) {}
  }
  const loadData = useCallback(async () => {
    setIsLoading(true)
    filter.status = filter.status || null
    filter.searchText = filter.searchText || null
    filter.type = filter.type || null
    const query: any = await axiosClient().post(
      END_POINTS.PACKAGE_SETTING.SEARCH_PAYMENT_METHOD(),
      filter,
    )
    if (!!!query.data || query.data?.length === 0) {
      setDataTable([])
    } else {
      setDataTable(query.data)
    }
    setIsLoading(false)
  }, [filter])
  useEffect(() => {
    loadData()
  }, [filter])

  const handleGetNameToken = (tokenId: string) =>
    wallets.find((token) => token?.id === tokenId)?.name

  const handleGenderDescription = (paymentMethod: PaymentMethodOption, type: string) => {
    switch (type) {
      case PAYMENT_METHODS.SINGLE:
        const walletName = handleGetNameToken(
          paymentMethod.paymentMethodOptionDetails[0].tokenItemId,
        )
        return `Single Payment Method with token ${walletName?.toUpperCase()}`
      case PAYMENT_METHODS.COMBINE:
        return handleGetPaymentMethodPercent(paymentMethod)
    }
  }

  const handleGetPaymentMethodPercent = (paymentMethod: PaymentMethodOption) => {
    return paymentMethod.paymentMethodOptionDetails
      .map(
        ({ tokenItemId, percentage }) =>
          `${handleGetNameToken(tokenItemId)} ${percentage > 0 ? `minimum  (${percentage}%)` : ''}`,
      )
      .join(' + ')
  }

  const handleUpdateSingleMethod = async (singlePaymentMethod: any) => {
    const data = {
      id: editId,
      description: handleGenderDescription(singlePaymentMethod, PAYMENT_METHODS.SINGLE),
      ...singlePaymentMethod,
    }
    const response: any = await axiosClient().put(
      END_POINTS.PAYMENT.UPDATE_PAYMENT_METHOD(editId),
      data,
    )
    if (response) {
      OpenAlert('Update single method payment successfully!', '', 'success')
      setShowModal(false)
    }
    loadData()
    setShowModal(false)
    setMyAction('')
    setItemEdit(undefined)
  }

  const transformData = (data: any) => ({
    name: data.formValues.name,
    type: data.formValues.walletType,
    description: `Custom Payment Method with `,
    paymentMethodOptionDetails: data.paymentWallets.map((wallet: any) => ({
      tokenItemId: wallet.idWallet,
      percentage: wallet.requiredPercent ? wallet.value : 0,
      isFixedPercentage: wallet.fixed,
    })),
  })

  const handleUpdateCombinePaymentMethod = async (combinePaymentMethod: any) => {
    combinePaymentMethod = transformData(combinePaymentMethod)
    const paymentMethodPercent = handleGenderDescription(
      combinePaymentMethod,
      PAYMENT_METHODS.COMBINE,
    )
    combinePaymentMethod.description = combinePaymentMethod.description + paymentMethodPercent
    const data = {
      id: editId,
      ...combinePaymentMethod,
    }
    const response: any = await axiosClient().put(
      END_POINTS.PAYMENT.UPDATE_PAYMENT_METHOD(editId),
      data,
    )
    if (response) {
      OpenAlert('Update combine method payment successfully!', '', 'success')
      setShowModal(false)
    }
    loadData()
    setShowModal(false)
    setMyAction('')
    setItemEdit(undefined)
  }

  const handleStatusChange = async (status: any): Promise<void> => {
    try {
      const response: any = await axiosClient().put(
        END_POINTS.PAYMENT.PUT_PAYMENT_METHOD_STATUS(editId),
        {
          id: editId,
          status: status ? 'ACTIVE' : 'INACTIVE',
        },
      )
      if (response) {
        OpenAlert('Update Status method payment successfully!', '', 'success')
        setShowModal(false)
      }
      loadData()
    } catch (error) {}
  }

  const handleAddCombineMethod = async (combinePaymentMethod: any) => {
    combinePaymentMethod = transformData(combinePaymentMethod)
    const paymentMethodPercent = handleGenderDescription(
      combinePaymentMethod,
      PAYMENT_METHODS.COMBINE,
    )
    combinePaymentMethod.description = combinePaymentMethod.description + paymentMethodPercent
    try {
      const res = await axiosClient().post(
        END_POINTS.PAYMENT.CREATE_PAYMENT_METHOD(),
        combinePaymentMethod,
      )
      if (res) {
        OpenAlert('Added new combine payment method successfully!', '', 'success')
      }
      loadData()
      setMyAction('')
      setShowModal(false)
    } catch (error) {
      console.error(error)
    }
  }
  const handleAddMethodSinglePayment = async (singlePaymentMethod: any) => {
    try {
      const data = {
        ...singlePaymentMethod,
        description: handleGenderDescription(singlePaymentMethod, PAYMENT_METHODS.SINGLE),
      }
      const res = await axiosClient().post(END_POINTS.PAYMENT.CREATE_PAYMENT_METHOD(), data)
      if (res) {
        OpenAlert('Added new single method payment successfully!', '', 'success')
        setShowModal(false)
      }

      loadData()
    } catch (error) {
      console.error(error)
    }
  }
  const handleSelectMethod = (e: any) => {
    setShowModal(false)
    setSelectedType(e)
    setTimeout(() => {
      setShowModal(true)
    }, 100)
  }
  return (
    <>
      <div className="container-fluid">
        <FilterPaymentMethod
          filter={filter}
          onFilter={(filter: IPaymentMethodFitter) => {
            setFilter(filter)
          }}
        />

        <div className="card border-0 mt-2 rounded">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <h4 className="mb-0">List Payment Method</h4>
              </div>
              <div className="col">
                <button className="btn btn-sm btn-primary btn-2 float-end">
                  <div
                    className="d-flex align-items-center gx-3 text-uppercase"
                    onClick={handleAddPayment}
                  >
                    <i className="bi bi-plus"></i>
                    <span>Add paymemt method</span>
                  </div>
                </button>
              </div>
            </div>
            {isLoading && <Loader />}
          </div>
        </div>
        <>
          <ListPaymentMethod
            data={dataTable}
            onClick={onClickPaymentMethodDetail}
            onChange={onChangePaymentMethodStatus}
          />
        </>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-md"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {myAction === actions.add && <>Add payment method </>}
            {myAction === actions.edit && <>Edit payment method </>}
          </Modal.Title>
        </Modal.Header>
        {myAction === actions.add && selectedType === '' && (
          <FormAddNewPaymentMethod onSubmit={handleSelectMethod} />
        )}
        {myAction === actions.add &&
          selectedType !== PAYMENT_METHODS.COMBINE &&
          selectedType !== '' && (
            <FormAddSinglePaymentMethod
              wallets={wallets}
              onSubmit={handleAddMethodSinglePayment}
              onCancel={() => setShowModal(false)}
              statusData={statusData}
            />
          )}
        {myAction === actions.add && selectedType === PAYMENT_METHODS.COMBINE && (
          <FormAddCombinePaymentMethod
            wallets={wallets}
            onSubmit={handleAddCombineMethod}
            onCancel={() => setShowModal(false)}
            type={selectedType}
            statusData={statusData}
          />
        )}
        {itemEdit && myAction === actions.edit && selectedType !== PAYMENT_METHODS.COMBINE && (
          <FormAddSinglePaymentMethod
            wallets={wallets}
            onSubmit={handleUpdateSingleMethod}
            onCancel={() => setShowModal(false)}
            edit={itemEdit}
            onStatusChange={handleStatusChange}
            statusData={statusData}
          />
        )}
        {itemEdit && myAction === actions.edit && selectedType === PAYMENT_METHODS.COMBINE && (
          <FormAddCombinePaymentMethod
            wallets={wallets}
            onSubmit={handleUpdateCombinePaymentMethod}
            onCancel={() => setShowModal(false)}
            data={itemEdit}
            edit={itemEdit}
            onStatusChange={handleStatusChange}
            statusData={statusData}
          />
        )}
      </Modal>
    </>
  )
}
export default PaymentMethodComponent
