import React, { useEffect } from 'react'
import { LOGIN_ROUTE } from 'helpers/constants'

import { ERROR_401_EVENT, ERROR_403_EVENT } from 'helpers/constants'
import PubSub from 'pubsub-js'

type Props = {
  children?: React.ReactNode
}

const NonAuthLayout = (props: Props) => {
  var { children } = props
  useEffect(() => {
    PubSub.subscribe(ERROR_401_EVENT, async () => {
      window.location.replace(LOGIN_ROUTE)
    })

    PubSub.subscribe(ERROR_403_EVENT, async () => {
      window.location.replace('/403')
    })
  }, [])

  return <>{children}</>
}

export default NonAuthLayout
