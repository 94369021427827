import React from 'react'
import { routes } from 'routes'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthProvider from 'contexts/AuthContexts'
import AuthLayout from 'layouts/AuthLayout'
import NonAuthLayout from 'layouts/NonAuthLayout'
import Page403 from 'pages/error/403'
import Page404 from 'pages/error/404'
import LoginPage from 'pages/login'
import { LOGIN_ROUTE } from 'helpers/constants'

function App() {
  return (
    <div>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path={LOGIN_ROUTE} element={<LoginPage />}></Route>
            {routes.map((route, idx) => {
              if (route?.children) {
                return route.children?.map((routeCh, idxCh) => {
                  return (
                    <Route
                      key={idxCh}
                      path={routeCh.path}
                      element={
                        <React.Suspense key={`children-${idxCh}`} fallback={<>...</>}>
                          <AuthProvider>
                            <AuthLayout roles={routeCh.roles}>
                              <routeCh.component />
                            </AuthLayout>
                          </AuthProvider>
                        </React.Suspense>
                      }
                    />
                  )
                })
              } else {
                return (
                  <Route
                    key={idx}
                    path={route.path}
                    element={
                      route.useAuthLayout ? (
                        <React.Suspense fallback={<>...</>}>
                          <AuthProvider>
                            <AuthLayout roles={route.roles}>
                              <route.component />
                            </AuthLayout>
                          </AuthProvider>
                        </React.Suspense>
                      ) : (
                        <React.Suspense fallback={<>...</>}>
                          <NonAuthLayout>
                            <route.component />
                          </NonAuthLayout>
                        </React.Suspense>
                      )
                    }
                  />
                )
              }
            })}
            <Route path="/403" element={<Page403 />}></Route>
            <Route path="*" element={<Page404 />}></Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </div>
  )
}

export default App
