import swal from 'sweetalert'
import { ContentOptions } from 'sweetalert/typings/modules/options/content'
export default function OpenAlert(
  title: string,
  text: string,
  icon: string,
  content?: ContentOptions,
) {
  swal({
    title: title,
    text: text,
    icon: icon,
    content: content,
  })
}
