import axios, { AxiosInstance } from 'axios'
import Agent from 'agentkeepalive'
import OpenAlert from 'components/sweetAlert'
import { ERROR_401_EVENT, LOADING, ERROR_403_EVENT } from 'helpers/constants'
import { errorMessage } from 'helpers/error-message'
import PubSub from 'pubsub-js'

const keepaliveAgent = new Agent({
  maxSockets: 40,
  maxFreeSockets: 10,
})

const defaultOptions = {
  httpAgent: keepaliveAgent,
  HttpsAgent: keepaliveAgent,
}
export const createAxiosInstance = (options: any): AxiosInstance => {
  PubSub.publish(LOADING, true)
  const axiosInstance = axios.create(Object.assign({}, defaultOptions, options))
  axiosInstance.defaults.timeout = 200000

  axiosInstance.interceptors.response.use(
    (response) => {
      PubSub.publish(LOADING, false)
      return response
    },
    (error) => {
      PubSub.publish(LOADING, false)
      const response = error.response
      if (!response) {
        return
      }
      if (response.status == '401') {
        PubSub.publish(ERROR_401_EVENT)
        OpenAlert(
          `${response?.statusText}`,
          `Your token has expired or is no longer active.
          Please sign in again to refresh your token!`,
          'error',
        )
        sessionStorage.setItem('retrySignIn', `${1}`)
      } else if (response.status == '403') {
        PubSub.publish(ERROR_403_EVENT)
        OpenAlert(
          `${response?.statusText}`,
          `You don’t have permission to access this resource.`,
          'error',
        )
      } else {
        const customErrCodeBigdataBuyAlready = response?.data?.errors
          ? response?.data?.errors['UserIdAndMonthlyPassId'][0]
          : null
        const errmsg =
          errorMessage[`${customErrCodeBigdataBuyAlready}`] ||
          errorMessage[`${response?.data?.title}`] ||
          response?.data?.title ||
          errorMessage['ERROR-COMMON']
        OpenAlert(`${response?.statusText}`, errmsg, 'error')
      }

      return Promise.reject(response)
    },
  )
  return axiosInstance
}
