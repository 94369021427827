import { ROLE } from 'helpers/constants'
import FeeConfiguration from 'pages/fee-configuration'
import React from 'react'
import PackagesManagement from 'pages/system-management/package-management'
import { LOGIN_ROUTE } from 'helpers/constants'
const HomePage = React.lazy(() => import('pages/home-page'))
const UserPage = React.lazy(() => import('pages/user'))
const MembershipPack = React.lazy(() => import('pages/member-pack'))
// const NetworkTree = React.lazy(() => import('pages/tree'))
// const MatrixTree = React.lazy(() => import('pages/matrix-tree'))
const TreeManagement = React.lazy(() => import('pages/tree-management'))
const PageWallets = React.lazy(() => import('pages/wallets'))
const TokenRate = React.lazy(() => import('pages/token-rate'))
const PrizePlan = React.lazy(() => import('pages/ticket-prize-plan'))
const NftManagement = React.lazy(() => import('pages/nft-management'))
const ImagesManagement = React.lazy(() => import('pages/images-management'))
const UserPackageManagement = React.lazy(() => import('pages/user-package'))
const UserSaleVolume = React.lazy(() => import('pages/user-sale-volume'))
const ExternalTransactionsPage = React.lazy(() => import('pages/external-transactions'))
const FunctionsSetting = React.lazy(() => import('pages/system-management/functions-setting'))
const PackageStatistics = React.lazy(() => import('pages/report/package-statistics'))
const CashflowStatistics = React.lazy(() => import('pages/report/statistic'))
const LoginPage = React.lazy(() => import('pages/login'))

export interface RouteConfigObject {
  path: string
  name?: string
  icon?: string
  title?: string
  exact?: boolean
  component?: any
  useAuthLayout?: boolean
  useAdminLayout?: boolean
  roles?: any
  action?: string
  isShow?: boolean
  children?: RouteConfigObject[]
}

const routes: Array<RouteConfigObject> = [
  {
    path: LOGIN_ROUTE,
    name: 'login',
    component: LoginPage,
    icon: 'bi bi-boxes',
    title: 'Login',
    useAuthLayout: false,
    isShow: false,
    roles: null,
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    icon: 'bi bi-boxes',
    title: 'Dashboard',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR, ROLE.APPROVER],
  },
  {
    path: '/users',
    name: 'UserPage',
    icon: 'bi bi-people',
    component: UserPage,
    title: 'User management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/network-tree',
    name: 'Tree',
    component: TreeManagement,
    icon: 'bi bi-bezier',
    title: 'Tree management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    // children: [
    //   {
    //     path: '/network-tree',
    //     name: 'NetworkTree',
    //     component: NetworkTree,
    //     icon: 'bi bi-bezier',
    //     title: 'Network Tree',
    //     useAuthLayout: true,
    //     isShow: true,
    //     roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    //   },
    //   {
    //     path: '/matrix-tree',
    //     name: 'NetworkTree',
    //     component: MatrixTree,
    //     icon: 'bi bi-bezier',
    //     title: 'Matrix Tree',
    //     useAuthLayout: true,
    //     isShow: true,
    //     roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    //   },
    // ],
  },
  {
    path: '/memberships',
    name: 'MembershipPack',
    component: MembershipPack,
    icon: 'bi bi-credit-card-2-back',
    title: 'Membership',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/wallets',
    name: 'Wallets',
    component: PageWallets,
    icon: 'bi bi-wallet2',
    title: 'Wallets management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/token-rate',
    name: 'TokenRate',
    component: TokenRate,
    icon: 'bi bi-graph-up',
    title: 'Token Rate',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/ticket-prize-plan',
    name: 'PrizePlan',
    component: PrizePlan,
    icon: 'bi bi-ticket-detailed',
    title: 'Prize Plan',
    useAuthLayout: true,
    isShow: false,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/nft-management',
    name: 'nft-management',
    component: NftManagement,
    icon: 'bi bi-ticket-detailed',
    title: 'NFT Management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/user-package-management',
    name: 'user-package-management',
    component: UserPackageManagement,
    icon: 'bi bi-file-earmark-zip',
    title: 'User package',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/user-sale-volume',
    name: 'user-sale-volume',
    component: UserSaleVolume,
    icon: 'bi bi-clipboard-data',
    title: 'Network Sale Volumes',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/external-transactions',
    name: 'External transactions',
    component: ExternalTransactionsPage,
    icon: 'bi bi-wallet',
    title: 'External transactions',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/fee-configuration',
    name: 'Fee configuration',
    component: FeeConfiguration,
    icon: 'bi bi-gear',
    title: 'Fee configuration',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: 'functions-setting',
    name: 'System Management',
    component: FunctionsSetting,
    icon: 'bi bi-gear',
    title: 'System management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    children: [
      {
        path: '/functions-setting',
        name: 'Functions setting',
        component: FunctionsSetting,
        icon: 'bi bi-gear',
        title: 'Functions setting',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/fee-configuration',
        name: 'Fee configuration',
        component: FeeConfiguration,
        icon: 'bi bi-gear',
        title: 'Fee configuration',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/images-management',
        name: 'Images management',
        component: ImagesManagement,
        icon: 'bi bi-gear',
        title: 'Images management',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/package-management',
        name: 'Packages Setting',
        component: PackagesManagement,
        icon: 'bi bi-gear',
        title: 'Packages Setting',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
    ],
  },
  {
    path: '/package-statistics',
    name: 'Statistics',
    component: PackageStatistics,
    icon: 'bi bi-bar-chart-line',
    title: 'Statistics',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    children: [
      {
        path: '/package-statistics',
        name: 'Package Statistics',
        component: PackageStatistics,
        icon: 'bi bi-bar-chart-line',
        title: 'Package statistics',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/cashflow-statistics',
        name: 'Cashflow Statistic',
        component: CashflowStatistics,
        icon: 'bi bi-bar-chart-line',
        title: 'Cashflow Statistic',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
    ],
  },
]
export { routes }
