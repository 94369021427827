import upperCase from 'lodash/upperCase'
import lowerCase from 'lodash/lowerCase'
import lowerFirst from 'lodash/lowerFirst'
const stringHelper = {
  lowerFirst(value?: string) {
    if (!value) return value

    return lowerFirst(value)
  },
  lowerString(value?: string) {
    if (!value) return value

    return lowerCase(value)
  },
  upperString(value?: string) {
    if (!value) return value

    return upperCase(value)
  },
}

export default stringHelper
