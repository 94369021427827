import FormConfigFee from 'components/form-config-fee'
import ListFeeConfig from 'components/list-fee-config'
import OpenAlert from 'components/sweetAlert'
import { FEE_TYPE, LOADING, PAYMENT_METHOD, STYPE_SELECT_REACT } from 'helpers/constants'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import { FeeConfig } from 'models'
import React, { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import { Modal } from 'react-bootstrap'
const ACTIONS = { ADD: 'ADD', EDIT: 'EDIT' }

const STATUS = [
  { title: 'Active', value: 'Active' },
  { title: 'Inactive', value: 'Inactive' },
]

function FeeConfiguration() {
  const [isLoading, setIsLoading] = useState(false)
  const [listFee, setListFee] = useState<Array<FeeConfig>>([])
  const [showModal, setShowModal] = useState(false)
  const [itemDetail, setItemDetail] = useState<FeeConfig | undefined>()
  const [myAction, setMyAction] = useState<string | null>(null)
  const [typeFee, setTypeFee] = useState<any>()
  const [method, setMethod] = useState<any>()
  const [status, setStatus] = useState<any>()
  const loadData = useCallback(async () => {
    const params = {
      type: typeFee?.value,
      paymentMethod: method?.value,
      status: status?.value,
    }
    setIsLoading(true)
    const query: any = await axiosClient().get(END_POINTS.FEE_CONFIG.LIST_FEES(), { params })
    setIsLoading(false)
    if (!query.data) return
    setListFee(query.data)
  }, [typeFee?.value, method?.value, status?.value])

  useEffect(() => {
    loadData()
  }, [loadData, typeFee?.value, method?.value, status?.value])

  const actionSubmit = async (bodyParams: any) => {
    if (myAction === ACTIONS.EDIT) {
      return await axiosClient().post(END_POINTS.FEE_CONFIG.UPDATE_FEE(), bodyParams)
    }
    if (myAction === ACTIONS.ADD) {
      return await axiosClient().post(END_POINTS.FEE_CONFIG.ADD_FEE(), bodyParams)
    }
  }

  const handleSubmitForm = async (values: FeeConfig) => {
    const bodyParams = {
      ...values,
      feeRate: Number(values.feeRate) / 100,
    }
    if (myAction === ACTIONS.EDIT && itemDetail) {
      bodyParams.id = itemDetail.id
    }
    setIsLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setIsLoading(data)
    })
    const res = await actionSubmit(bodyParams)
    if (res) {
      OpenAlert(`${myAction === ACTIONS.ADD ? 'Add' : 'Update'} Success!`, ``, 'success')
      loadData()
      setShowModal(false)
      setItemDetail(undefined)
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    loadData()
  }

  return (
    <>
      <div className="container-fluid ">
        <div className="row align-items-center page-title">
          <div className="col-12 col-md mb-2 mb-sm-0">
            <h3 className="mb-0">Fee Configuration</h3>
          </div>
        </div>
        <hr className="p-0 m-0 mb-4" />
      </div>

      <div className="container-fluid">
        <div className="d-flex align-items-end justify-content-end mt-3">
          <div className="card border-0 rounded" style={{ minWidth: '100%' }}>
            <div className="card-header rounded d-inline-block">
              <form onSubmit={handleSubmit}>
                <div className="row gx-1 align-items-end">
                  <div className={`col-md-3`}>
                    <div className="row gx-1">
                      <div className="col-md  mb-2">
                        <label className="lbl mb-1">Type of Fee</label>
                        <div className="d-flex align-items-center">
                          <div className="col">
                            <Select
                              options={FEE_TYPE}
                              value={typeFee}
                              name="selectType"
                              placeholder="Select type"
                              formatOptionLabel={(options: any) => <b>{options.title}</b>}
                              isClearable={true}
                              onChange={(e: any) => setTypeFee(e)}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                control: (provided: Record<string, unknown>) => ({
                                  ...provided,
                                  ...STYPE_SELECT_REACT,
                                }),
                              }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-3`}>
                    <div className="row gx-1">
                      <div className="col-md  mb-2">
                        <label className="lbl mb-1">Payment Method</label>
                        <div className="d-flex align-items-center">
                          <div className="col">
                            <Select
                              options={PAYMENT_METHOD}
                              value={method}
                              name="selectMethod"
                              placeholder="Select method"
                              formatOptionLabel={(options: any) => <b>{options.title}</b>}
                              isClearable={true}
                              onChange={(e: any) => {
                                setMethod(e)
                              }}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                control: (provided: Record<string, unknown>) => ({
                                  ...provided,
                                  ...STYPE_SELECT_REACT,
                                }),
                              }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-3`}>
                    <div className="row gx-1">
                      <div className="col-md  mb-2">
                        <label className="lbl mb-1">Status</label>
                        <div className="d-flex align-items-center">
                          <div className="col">
                            <Select
                              options={STATUS}
                              value={status}
                              name="selectStatus"
                              placeholder="Select status"
                              formatOptionLabel={(options: any) => <b>{options.title}</b>}
                              isClearable={true}
                              onChange={(e: any) => setStatus(e)}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                control: (provided: Record<string, unknown>) => ({
                                  ...provided,
                                  ...STYPE_SELECT_REACT,
                                }),
                              }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto mb-2">
                    <button
                      className="btn btn-primary btn-md border-0 px-2"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <small>Search</small>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card border-0 mt-3 rounded">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <h4 className="mb-0">List Fees</h4>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary border-0 btn-sm"
                  onClick={() => {
                    setMyAction(ACTIONS.ADD)
                    setShowModal(true)
                  }}
                >
                  <i className="bi bi-plus"></i> Add Fee Configuration
                </button>
              </div>
              {isLoading && (
                <div className="loading-data">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"> </span>
                  </div>
                </div>
              )}
              <ListFeeConfig
                data={listFee}
                onClickDetail={(item: FeeConfig) => {
                  setShowModal(true)
                  setMyAction(ACTIONS.EDIT)
                  setItemDetail(item)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false)
            setItemDetail(undefined)
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {myAction === ACTIONS.EDIT ? 'Edit ' : 'Add '}Fee Configuration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormConfigFee
              action={myAction}
              itemDetail={itemDetail}
              onSubmit={handleSubmitForm}
              isLoading={isLoading}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default FeeConfiguration
