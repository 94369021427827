import { AuthContext } from 'contexts/AuthContexts'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { routes } from 'routes'

function Sidebar() {
  const { user } = useContext(AuthContext)
  const location = useLocation()
  return (
    <>
      <div className="sidebar-wrap">
        <div className="sidebar">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12 px-0">
                <ul className="nav nav-pills">
                  {routes.map((route, idx) =>
                    route.roles?.length > 0 && !route.roles.includes(user.profile.role) ? null : (
                      <li key={idx} className="nav-item">
                        {route.isShow !== false &&
                          (!route.children ? (
                            <Link
                              className={`nav-link three-dot ${
                                location.pathname === route.path ? 'active' : ''
                              }`}
                              aria-current="page"
                              to={route.path}
                            >
                              <div className="avatar avatar-40 icon">
                                <i className={route.icon}></i>
                              </div>
                              <div className="col">{route.title}</div>
                              <div className="arrow">
                                <i className="bi bi-chevron-right"></i>
                              </div>
                            </Link>
                          ) : (
                            <div
                              className={`pointer three-dot nav-link ${
                                location.pathname.includes(route.path) ? 'active' : ''
                              }`}
                              aria-current="page"
                            >
                              <div className="avatar avatar-40 icon">
                                <i className={route.icon}></i>
                              </div>
                              <div className="col">{route.title}</div>
                              <div className="arrow">
                                <i className="bi bi-chevron-down"></i>
                              </div>
                            </div>
                          ))}
                        {route.children &&
                          route.isShow !== false &&
                          route.children.map((child, idx) => {
                            return (
                              child?.isShow !== false && (
                                <Link
                                  key={idx + 'child'}
                                  className={`sub-menu ${
                                    location.pathname === child.path ? 'active' : ''
                                  }`}
                                  aria-current="page"
                                  to={child.path}
                                >
                                  <div className="cover-sub-menu ms-3 d-flex align-items-center px-4">
                                    <div
                                      className={`sub-menu-dot ${
                                        location.pathname === child.path ? 'active' : ''
                                      }`}
                                    ></div>
                                    <div className="col ms-2">{child.title}</div>
                                  </div>
                                </Link>
                              )
                            )
                          })}
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
