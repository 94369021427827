import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import moment from 'moment'
export function formatDate(date: Date | string) {
  return moment(date).format('MMM Do, YYYY')
}
export function formatDateTime(date: Date | string) {
  return moment(date).format('MMM Do, YYYY hh:mm a')
}
export function formatAmount(amount: number) {
  if (!amount) amount = 0
  return `$${amount ? amount : 0}`
}
export function formatCurrency(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}
export function formatDateParam(date: Date | string) {
  return moment(date).format('YYYY-MM-DD')
}
export function limitTransactionHash(str: string) {
  let length = 20,
    start = 6,
    end = 27
  if (str?.length <= length) return str
  return str ? str.replace(str.substring(start, end), '...') : ''
}
export function formatCoin(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
  })
}

export function formatStableCoin(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'decimal',
    // currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function validateEmail(inputText: string) {
  var mailformat =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  if (inputText.match(mailformat)) {
    return true
  } else {
    return false
  }
}
export function validatePassword(inputtxt: string) {
  var passw = /^null|$/
  if (inputtxt.match(passw)) {
    return true
  } else {
    return false
  }
}

export function checkConfirmPassword(passStringConnfirm: string, passString: string) {
  if (validatePassword(passStringConnfirm) && passStringConnfirm === passString) {
    return true
  } else {
    return false
  }
}
export function validateName(inputtxt: string) {
  var myformat = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/
  if (inputtxt.match(myformat)) {
    return true
  } else {
    return false
  }
}

export async function checkUserName(inputtxt: string) {
  const queryCheckUsernameExist = await axiosClient().get(
    END_POINTS.USER_API_END_POINTS.IS_EXIST_USERNAME(inputtxt),
  )
  if (queryCheckUsernameExist.data === true) {
    return false
  }
  return true
}

export function loadImgRank(rankName: string | null) {
  const urlImgRank = '/assets/img/ranks/'
  const imgName = rankName?.replaceAll(' ', '_').toLowerCase()
  if (rankName) return urlImgRank + imgName + '.png'
  else return urlImgRank + 'moongle.png?v=0.0.1'
}

export function loadImgPack(packname: string) {
  const urlImgRank = '/assets/img/packs/'
  return urlImgRank + packname + '.png'
}

export function loadImgWallet(name: string) {
  const urlImg = '/assets/img/wallets/'
  const imgname = name.split('_')[0]
  if (imgname) return urlImg + imgname.toLowerCase() + '.png'
}
export function hideEmail(email: string) {
  return email.replace(/(\w{4}).*?@/, '$1***@')
}
export function hideString(text: string) {
  if (text) {
    const l = text.length
    const count = l > 3 ? 4 : l
    return '***' + text.substr(text.length - count, count)
  }
  return ''
}
export function validateTime(time: string) {
  if (time) {
    const timeReg = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
    return time.match(timeReg)
  }
}

export function getTimeForInput(strDateTime: string) {
  if (strDateTime) {
    const myH =
      new Date(strDateTime).getHours() > 9
        ? new Date(strDateTime).getHours()
        : `0${new Date(strDateTime).getHours()}`
    const myM =
      new Date(strDateTime).getMinutes() > 9
        ? new Date(strDateTime).getMinutes()
        : `0${new Date(strDateTime).getMinutes()}`

    return `${myH}:${myM}`
  }
  return null
}

export function formatStableCoinUSD(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
