import React from 'react'
function Footer() {
  return (
    <>
      <footer className="footer mt-auto">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md col-lg py-2">
              <span className="text-secondary small">
                Copyright @2022,{' '}
                <a href="#" target="_blank">
                  VLINKMETMALL
                </a>
              </span>
            </div>
            <div className="col-12 col-md-auto col-lg-auto align-self-center">
              <ul className="nav small">
                <li className="nav-item">
                  <a className="nav-link" href="https://vlinkmeta.com/terms">
                    Terms of Use
                  </a>
                </li>
                <li className="nav-item">|</li>
                <li className="nav-item">
                  <a className="nav-link" href="https://vlinkmeta.com/policy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
